




























































import { Component, Vue } from "vue-property-decorator";
import ReportFooter from "@/components/ReportFooter.vue";

@Component({ name: "assignees-schedule", components: { ReportFooter } })
export default class AssigneesSchedule extends Vue {
  /* Variables */

  filters = {
    site_group: (null as unknown) as string,
    site_code: (null as unknown) as string,
    assignees_type: (null as unknown) as string,
    listing_type: (null as unknown) as string,
  };

  /* Computed */
  /* Methods */

  doDownload(something: string[]) {
    console.log(something);
  }

  /* Life-cycle Methods */
}
