




































import { Component, Vue } from "vue-property-decorator";
import HorizontalProgress from "@/components/HorizontalProgress.vue";
import { DataTableHeader } from "vuetify";

type dummyItems = {
  respondMinute: string;
  bar1: number;
  bar2: number;
};
@Component({
  name: "ticket-response",
  components: { HorizontalProgress },
})
export default class TicketResponse extends Vue {
  /* Variables */

  isLoading = false;

  dummyItems = [] as dummyItems[];

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Responded in Minute", value: "respondMinute" },
      { text: "No of Signal", value: "bar1", width: '70%' },
    ];
  }

  /* Method */

  fetchData() {
    this.isLoading = true;
    for (let x = 0; x < 3; x++) {
      this.dummyItems.push({
        respondMinute: "Column 1 Item " + x,
        bar1: 10,
        bar2: 5,
      });
    }
    this.isLoading = false;
  }

  toPage(value: dummyItems) {
    console.log(value);
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
  }
}
