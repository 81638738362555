








































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({ name: "site-panel" })
export default class SitePanel extends Vue {}
