




















import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

type dummyItems = {
  column1: string;
  column2: string;
  bar1: number;
  bar2: number;
};
@Component({ name: "services-done" })
export default class ServicesDone extends Vue {
  /* Variables */

  isLoading = false;

  dummyItems = [] as dummyItems[];

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "ID", value: "id" },
      { text: "Date Created", value: "createdAt" },
      { text: "Job Code", value: "jobCode" },
      { text: "Service Done", value: "service" },
      { text: "Remark", value: "remark" },
      { text: "Qty", value: "qty" },
      { text: "Comments", value: "comments" },
      { text: "Actions", value: "actions" },
    ];
  }

  /* Method */

  fetchData() {
    this.isLoading = true;
    for (let x = 0; x < 3; x++) {
      this.dummyItems.push({
        column1: "Column 1 Item " + x,
        column2: "Column 2 Item " + x,
        bar1: 10,
        bar2: 5,
      });
    }
    this.isLoading = false;
  }

  toPage(value: dummyItems) {
    console.log(value);
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
  }
}
