



































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({ name: "ticket-update" })
export default class TicketUpdate extends Vue {
  /* Variables */

  state = {
    detail: false,
  };

  /* Computed */

  /* Methods */
 
  /* Life-cycle Methods */
}
