









































import { Component, Vue } from "vue-property-decorator";
import { eightChar, required } from "@/shared/validations";
import { VuetifyForm } from "@/shared/types";
import { Auth, Notif } from "@/store";

@Component({ name: "account-login" })
export default class AccountLogin extends Vue {
  /* Variables */

  visible = true;

  private account = {
    username: "",
    password: "",
    extension: "",
  };

  private validation = {
    eightChar,
    required,
  };

  loading = false;

  /* Computed */

  /* Methods */

  public async login() {
    if (!(this.$refs.loginForm as VuetifyForm).validate()) return;

    try {
      this.loading = true;
      const resp = await Auth.Login({ ...this.account });
      if (resp) {
        return void this.$router.replace("/");
      }
      Notif.notif_success("Successfully Login !");
    } catch (error) {
      Notif.notif_error_api(error);
    } finally {
      this.loading = false;
    }
  }

  /* Life-cycle Methods */

  created() {
    if (Auth.is_login) {
      this.visible = false;
      this.$router.replace({ name: "Dashboard" });
    }
  }
}
