import { AppModule } from "@/shared/types";

import PageNotFound from "../NotFound/PageNotFound.vue";

const PanelManagementModule: AppModule[] = [
    {
        rootPath: '/panel-management',
        rootIcon: 'mdi-sim',
        rootName: 'Panel Management',
        authRequired: true,
        layout: "sidebar",
        pages: [
            {
                path: 'search-panel',
                name: "Search Panel",
                component: PageNotFound
            },
            {
                path: 'sim-inventory',
                name: "SIM Inventory",
                component: PageNotFound
            },
        ]
    }
]

export default PanelManagementModule;