













import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "status-chip" })
export default class StatusChip extends Vue {
  /* Constants */
  @Prop({ required: true }) readonly value!: string;

  @Prop() readonly size!: "xl" | "lg" | "sm" | "xs";

  /* Methods */
  getColor(status: string) {
    if (
      status == "Open" ||
      status == "Re Open" ||
      status == "Normal" ||
      status == "Reopen"
    )
      return "primary";
    if (status == "Assigned") return "info";
    if (status == "Pending") return "grey";
    if (status == "Closed") return "secondary";
    if (status == "Resolved") return "success";
    if (status == "In Progress") return "warning";
    if (status == "Service Requisition") return "blue accent-2";
    if (status == "Urgent" || status == "Rejected") return "error";
  }
}
