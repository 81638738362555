import { AppModule } from "@/shared/types";

import TicketIndex from "./Ticket/index.vue";
import TicketDetail from "./Ticket/detail.vue";

import ZoneTrigger from "./ZoneTrigger.vue";
import TicketInfo from "./TicketInformation/TicketInfo.vue";
import TicketUpdate from "./TicketInformation/TicketUpdate.vue";
import ServicesDone from "./TicketInformation/ServicesDone.vue";
import CMSInbox from "./TicketInformation/CMSInbox.vue";
import AssigneeInfo from "./TicketInformation/AssigneeInfo.vue";
import SMSSent from "./TicketInformation/SMSSent.vue";
import CallInfo from "./TicketInformation/CallInfo.vue";

import DisarmTriggerIndex from "./DisarmTrigger/index.vue";

import OpenTriggerIndex from "./OpenTrigger/index.vue";

import SiteInfo from "./SiteInformation/SiteInfo.vue";
import SiteHistory from "./SiteInformation/SiteHistory.vue";
import CardEntries from "./SiteInformation/CardEntries.vue";
import SiteTickets from "./SiteInformation/SiteTickets.vue";
import CallList from "./SiteInformation/CallList.vue";
import Triggers from "./SiteInformation/Triggers.vue";
import CallSchedule from "./SiteInformation/CallSchedule.vue";
import MessageSend from "./SiteInformation/MessageSend.vue";
import SitePanel from "./SiteInformation/SitePanel.vue";
import CallAdd from "./SiteInformation/CallAdd.vue";

import CustomerDetail from "./Ticket/CustomerDetail.vue";

// import PageNotFound from "../NotFound/PageNotFound.vue";

const TicketModule: AppModule[] = [
    {
        rootPath: '/ticket',
        rootIcon: 'mdi-ticket-confirmation',
        rootName: 'Ticket Management',
        authRequired: true,
        layout: "sidebar",
        pages: [
            // {
            //     path: 'task',
            //     name: 'Task',
            //     component: PageNotFound
            // },
            {
                path: 'all-ticket',
                name: 'Ticket',
                component: TicketIndex
            },
            {
                path: 'detail/:uuid',
                name: 'Ticket Detail',
                hidePage: true,
                component: TicketDetail
            },
            {
                path: 'zone-trigger',
                name: "Zone Trigger",
                component: ZoneTrigger
            },
            {
                path: 'zone-trigger/alarm/:uuid',
                name: "Zone Trigger Alarm Detail",
                hidePage: true,
                component: TicketInfo
            },
            {
                path: 'zone-trigger/alarm/update/:uuid',
                name: "Zone Trigger Alarm Update",
                hidePage: true,
                component: TicketUpdate
            },
            {
                path: 'zone-trigger/alarm/services-done/:uuid',
                name: "Zone Trigger Services Done",
                hidePage: true,
                component: ServicesDone
            },
            {
                path: 'zone-trigger/alarm/cms-inbox/:uuid',
                name: "Zone Trigger CMS Inbox",
                hidePage: true,
                component: CMSInbox
            },
            {
                path: 'zone-trigger/alarm/assignee-info/:uuid',
                name: "Zone Trigger Assignee Information",
                hidePage: true,
                component: AssigneeInfo
            },
            {
                path: 'zone-trigger/alarm/sms-sent/:uuid',
                name: "Zone Trigger SMS Sent",
                hidePage: true,
                component: SMSSent
            },
            {
                path: 'zone-trigger/alarm/call-info/:uuid',
                name: "Caller Information",
                hidePage: true,
                component: CallInfo
            },
            {
                path: 'zone-trigger/site-information/history/:uuid',
                name: "Company History Information",
                hidePage: true,
                component: SiteHistory
            },
            {
                path: 'zone-trigger/site-information/card-entries/:uuid',
                name: "Card Entries",
                hidePage: true,
                component: CardEntries
            },
            {
                path: 'zone-trigger/site-information/site-tickets/:uuid',
                name: "Site Tickets",
                hidePage: true,
                component: SiteTickets
            },
            {
                path: 'zone-trigger/site-information/call-list/:uuid',
                name: "Call List",
                hidePage: true,
                component: CallList
            },
            {
                path: 'zone-trigger/site-information/triggers/:uuid',
                name: "Ticket Triggers",
                hidePage: true,
                component: Triggers
            },
            {
                path: 'zone-trigger/site-information/call-schedule/:uuid',
                name: "Call List Schedule",
                hidePage: true,
                component: CallSchedule
            },
            {
                path: 'zone-trigger/site-information/message-send/:uuid',
                name: "Message Send",
                hidePage: true,
                component: MessageSend
            },
            {
                path: 'zone-trigger/site-information/site-panel/:uuid',
                name: "Site Panel Information",
                hidePage: true,
                component: SitePanel
            },
            {
                path: 'zone-trigger/site-information/call-add/:uuid',
                name: "Add Caller",
                hidePage: true,
                component: CallAdd
            },
            {
                path: 'disarm-trigger',
                name: "Disarm Trigger",
                component: DisarmTriggerIndex
            },
            {
                path: 'disarm-trigger/:uuid',
                name: "Disarm Trigger Detail",
                hidePage: true,
                component: TicketInfo
            },
            {
                path: 'open-trigger',
                name: "Open Trigger",
                component: OpenTriggerIndex
            },
            {
                path: 'open-trigger/:uuid',
                name: "Open Trigger Detail",
                hidePage: true,
                component: TicketInfo
            },

            /* Site Information */
            
            {
                path: 'site-information/:uuid',
                name: "Site Infomation",
                hidePage: true,
                component: SiteInfo
            },
            // {
            //     path: 'triggers',
            //     name: "Triggers",
            //     component: PageNotFound
            // },
            // {
            //     path: 'send-sms',
            //     name: "Send SMS",
            //     component: PageNotFound
            // },

            /* Customer */

            {
                path: 'customer/:uuid',
                name: 'Customer Detail',
                hidePage: true,
                component: CustomerDetail
            }
        ]
    }
]

export default TicketModule;