import { AppModule } from "@/shared/types";

import ComplaintsIndex from "./index.vue";
import ComplaintsDetails from "./detail.vue";

const ComplaintsModule: AppModule[] = [
    {
        rootPath: '/complaints',
        rootIcon: 'mdi-emoticon-confused',
        rootName: 'Complaints',
        authRequired: true,
        layout: "sidebar",
        pages: [
            {
                path: '/',
                name: "Complaints",
                icon: 'mdi-emoticon-confused',
                component: ComplaintsIndex
            },
            {
                path: ':uuid',
                name: "Complaint Details",
                hidePage: true,
                component: ComplaintsDetails
            },
        ]
    }
]

export default ComplaintsModule;