




























































import { Component, Vue } from "vue-property-decorator";
import ReportFooter from "@/components/ReportFooter.vue";

@Component({ name: "sites-techgroup", components: { ReportFooter } })
export default class SitesTechgroup extends Vue {
  /* Variables */

  filters = {
    site_group: (null as unknown) as string,
    tech_team: (null as unknown) as string,
    google_map_coordinates: (null as unknown) as string,
    output: (null as unknown) as string,
  };

  /* Computed */
  /* Methods */

  doDownload(something: string[]) {
    console.log(something);
  }

  /* Life-cycle Methods */
}
