import { AppModule } from "@/shared/types";

import AlarmLog from "./index.vue";
import AlarmLogDetail from "./detail.vue";

const AlarmModule: AppModule[] = [
    {
        rootPath: '/alarm-log',
        rootIcon: 'mdi-alarm-light',
        rootName: 'IPC Alarm Log',
        authRequired: false,
        layout: "sidebar",
        pages: [
            {
                path: '',
                name: "IPC Alarm Log",
                component: AlarmLog
            },
            {
                path: ':uuid',
                name: "IPC Alarm Detail",
                component: AlarmLogDetail
            },
        ]
    }
]

export default AlarmModule;