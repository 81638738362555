import { eject_token, inject_token } from "@/api";
import { Store } from "vuex";

const token_injector = (store: Store<any>) => {
    inject_token(store.getters['access_token'] as string);
    console.log("Injecting Token");

    store.subscribe((mutation) => {
        if (mutation.type === 'store_auth') {
            inject_token(mutation.payload.access_token)
            console.log("Re-Injecting Token");
        } else if (mutation.type === 'discard_auth') {
            eject_token()
            console.log("Ejecting Token");
        }
    })
}

export default token_injector