
















































































































































































































































import {
  get_contact_list,
  get_tech_group,
  get_zone_trigger_detail,
} from "@/api";
import {
  contactListType,
  techGroupType,
  zoneTriggerDetailType,
} from "@/api/api.types";
import { time_format } from "@/shared/utils";
import { Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "alarm-detail",
  components: {
    TicketHistory: () => import("./TicketHistory.vue"),
    TrackTime: () => import("./TrackTime.vue"),
    TicketFiles: () => import("./TicketFiles.vue"),
  },
})
export default class AlarmDetail extends Vue {
  /* Variables */

  data = {} as zoneTriggerDetailType["data"];

  contactData = [] as contactListType["data"]["_embedded"]["contact"];

  techData = [] as techGroupType["data"]["contact"];

  dialogs = {
    TicketHistory: "",
    TrackTime: "",
    TicketFiles: "",
  };

  /* Computed */

  /* Methods */

  async fetchData() {
    try {
      const resp: zoneTriggerDetailType = await get_zone_trigger_detail(
        this.$route.params.uuid
      );
      const d = resp.data;
      this.data = d;
      this.data.dateModified = time_format(d.dateModified)
      this.data.dateExpired = time_format(d.dateExpired)
      this.data.createdAt = time_format(d.createdAt)
      return resp;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchContactList(company_uuid?: number) {
    try {
      const resp: contactListType = await get_contact_list({
        company_uuid: company_uuid,
        call_list: 1,
      });
      this.contactData = resp.data._embedded.contact;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchTechGroup(group_uuid?: number) {
    try {
      const resp = await get_tech_group({ group_uuid });
      this.techData = resp.data.contact;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  getColor(status: string) {
    if (status == "Open" || status == "Re Open" || status == "Normal")
      return "primary";
    if (status == "Closed") return "secondary";
    if (status == "Resolved") return "success";
    if (status == "In Progress") return "warning";
    if (status == "Urgent") return "error";
  }

  /* Life-cycle Methods */

  mounted() {
    this.fetchData().then((resp) => {
      this.fetchContactList(resp?.data.company.uuid);
      this.fetchTechGroup(resp?.data.technicianGroup.uuid);
    });
  }
}
