





































import { Component, Vue } from "vue-property-decorator";
import HorizontalProgress from "@/components/HorizontalProgress.vue";
import { DataTableHeader } from "vuetify";

type dummyItems = {
  column1: string;
  column2: string;
  bar1: number;
  bar2: number;
};
@Component({
  name: "signal-count-type",
  components: { HorizontalProgress },
})
export default class SignalCountType extends Vue {
  /* Variables */

  isLoading = false;

  dummyItems = [] as dummyItems[];

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Site Code", value: "siteCode" },
      { text: "Site Name", value: "siteName" },
      { text: "False Alarm", value: "falseAlarm" },
      { text: "Customer at Site", value: "customerSite" },
      { text: "Notified Tech", value: "notifiedTech" },
      { text: "Xtend at Site", value: "xtendSite" },
      { text: "Pending Tech", value: "pendingTech" },
      { text: "Maintance", value: "maintance" },
      { text: "Red Alarm", value: "redAlarm" },
      { text: "Not Attended", value: "notAttended" },
      { text: "Not Action", value: "notAction" },
    ];
  }

  /* Method */

  fetchData() {
    this.isLoading = true;
    for (let x = 0; x < 3; x++) {
      this.dummyItems.push({
        column1: "Column 1 Item " + x,
        column2: "Column 2 Item " + x,
        bar1: 10,
        bar2: 5,
      });
    }
    this.isLoading = false;
  }

  toPage(value: dummyItems) {
    console.log(value);
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
  }
}
