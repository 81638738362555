import { AppModule } from "@/shared/types";

import IssueCreate from "./Create.vue";
import IssueOpen from "./Open.vue";

const Issue: AppModule[] = [
    {
        rootPath: '/issue',
        rootIcon: 'mdi-message-alert',
        rootName: 'Issue',
        authRequired: true,
        layout: "sidebar",
        pages: [
            {
                path: 'create',
                name: "Create Issue",
                icon: 'mdi-message-plus',
                component: IssueCreate
            },
            {
                path: 'open',
                name: "Open Issue",
                icon: 'mdi-message-bulleted',
                component: IssueOpen
            }
        ]
    }
]

export default Issue;