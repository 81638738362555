var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","id":"open-trigger-index"}},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-3"},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$route.name)}}),_c('span',{staticClass:"col-3 pa-0"},[_c('v-select',{attrs:{"dense":"","hide-details":"","outlined":"","items":_vm.triggerModeList},model:{value:(_vm.filter.mode),callback:function ($$v) {_vm.$set(_vm.filter, "mode", $$v)},expression:"filter.mode"}})],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableItems,"items-per-page":25,"loading":_vm.isLoading,"hide-default-footer":"","dense":""},on:{"click:row":_vm.toPage},scopedSlots:_vm._u([{key:"item.company.companyName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'open-trigger/site-information/' + item.company.uuid}},[_c('tool-truncate',{model:{value:(item.company.companyName),callback:function ($$v) {_vm.$set(item.company, "companyName", $$v)},expression:"item.company.companyName"}})],1)]}},{key:"item.contact.fullName",fn:function(ref){
var item = ref.item;
return [_c('tool-truncate',{attrs:{"value":item.contact.fullName}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('tool-truncate',{model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"x-small":""},domProps:{"textContent":_vm._s(item.status)}})]}}],null,true)}),_c('v-divider'),_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"disabled":!_vm.page.prev,"text":"","color":"primary"},on:{"click":function($event){return _vm.fetchData(_vm.page.prev)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left-box")]),_vm._v(" Previous ")],1),_c('v-btn',{attrs:{"disabled":!_vm.page.next,"text":"","color":"primary"},on:{"click":function($event){return _vm.fetchData(_vm.page.next)}}},[_vm._v(" Next "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right-box")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }