





























import { Component, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { Notif } from "@/store";

@Component({
  name: "cms-inbox",
})
export default class CMSInbox extends Vue {
  /* Variables */

  isLoading = false;

  filter = {
    search: null,
  };

  tableItems = [] as {
    coloumn1: string;
    coloumn2: string;
  }[];

  page = {
    page_count: 10,
    page_size: 25,
    page: 1,
  };

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Panel Time", value: "panelTime" },
      { text: "CMS Unit ID", value: "cmsUnitId" },
      { text: "CMS Unit Time", value: "cmsUnitTime" },
      { text: "Zone", value: "zone" },
      { text: "Channel", value: "channel" },
      { text: "Alarm Code", value: "alarmCode" },
      { text: "Content", value: "content" },
      { text: "Server Date/Time", value: "serverTIme" },
    ];
  }

  /* Method */

  async fetchData(toPage = this.page.page) {
    if (toPage == 0) toPage = 1;
    this.isLoading = true;
    try {
      this.tableItems = [
        { coloumn1: "string", coloumn2: "string" },
        { coloumn1: "string", coloumn2: "string" },
      ];
    } catch (error) {
      Notif.notif_error_api(error);
    } finally {
      this.isLoading = false;
    }
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
  }

  @Watch("page.page")
  onPageChange(no: number) {
    if (no) this.fetchData();
  }
}
