

































































































































































































































































































































































import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import StatusChip from "@/components/StatusChip.vue";
import AddTicket from "../Complaints/AddTicket.vue";
import { fullName, time_format } from "@/shared/utils";
import {
  requestAttachmentType,
  requestDetailType,
  requestResponseType,
  requestStatusLogType,
} from "@/api/api.types";
import {
  get_request_attachment,
  get_request_detail,
  get_request_response,
  get_request_status,
  get_request_status_log,
  patch_request,
  post_request_response,
} from "@/api";
import { trigger } from "@/store/module/notif.store";

type thisRequestType = requestDetailType["data"];

@Component({ name: "request-detail", components: { StatusChip, AddTicket } })
export default class RequestDetail extends Vue {
  /* Variables */

  detailData = {} as thisRequestType;

  uuid = this.$route.params.uuid;

  requestCustomer = {} as thisRequestType["customer"];

  requestedByData = {} as thisRequestType["requestedBy"];

  requestChargeData = null as thisRequestType["requestCharge"];

  requestResponseData = [] as requestResponseType["data"]["_embedded"]["requestResponse"];

  tableData = [] as requestStatusLogType["data"]["_embedded"]["requestStatusLog"];

  photoOverlay = (null as unknown) as string;

  pictView = (null as unknown) as string | ArrayBuffer | null;

  addTicket = (null as unknown) as {
    request: string;
    customer: { uuid: string; name: string };
  };

  requestResponse = {
    request: this.uuid,
    comment: "",
    photo: (null as unknown) as File,
  };

  requestAttachmentList = [] as requestAttachmentType['data']['_embedded']['requestAttachment']

  rejectDialog = false;

  rejectNote = "";

  customerPost = { lat: 0, lng: 0 };

  /* Computed */

  public get tableHeaders(): DataTableHeader[] {
    return [
      { text: "Status", value: "requestStatus" },
      { text: "Date Time", value: "createdAt" },
      { text: "Change By", value: "userProfile.firstName" },
      { text: "Note", value: "note" },
    ];
  }

  public get trigger(): trigger {
    return Notif.triggers;
  }

  /* Methods */

  async fetchData() {
    try {
      const resp = await get_request_detail(this.uuid);
      this.detailData = resp.data;
      this.detailData.createdAt = time_format(this.detailData.createdAt);
      const d = this.detailData;
      if (d.approvedBy) {
        d.approvedBy.firstName = fullName(
          d.approvedBy.firstName,
          d.approvedBy.lastName
        );
      }
      this.requestedByData.firstName = fullName(
        d.requestedBy.firstName,
        d.requestedBy.lastName
      );
      if (!this.requestedByData.address) this.requestedByData.address = "-";
      if (!this.requestedByData.unitNumber)
        this.requestedByData.unitNumber = "-";
      d.approvedBy;
      // this.requestChargeData = resp.data.requestCharge;
      // if (this.requestChargeData) {
      //   const d = this.requestChargeData;
      //   d.tax = `${d.currency} ${d.tax}`;
      //   d.amount = `${d.currency} ${d.amount}`;
      //   d.discount = `${d.currency} ${d.discount}`;
      //   d.total = `${d.currency} ${d.total}`;
      // }
      if (d.customer) {
        this.requestCustomer = d.customer;
        this.customerPost = {
          lat: parseFloat(d.customer.latitude),
          lng: parseFloat(d.customer.longitude),
        };
      }
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchDataTable() {
    try {
      const resp = await get_request_status_log({
        request_uuid: this.uuid,
      });
      this.tableData = resp.data._embedded.requestStatusLog;
      this.tableData.forEach((item) => {
        item.createdAt = time_format(item.createdAt);
        item.userProfile.firstName = fullName(
          item.userProfile.firstName,
          item.userProfile.lastName
        );
        if (!item.note) item.note = "-";
      });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchRequestStatus() {
    try {
      const status = await get_request_status();
      return status.data._embedded.requestStatus;
    } catch (error) {
      Notif.notif_error_api(error);
      return [];
    }
  }

  async updateRequestStatus(toStatus: string) {
    try {
      this.fetchRequestStatus().then(async (item) => {
        const stat = item.filter((filt) => filt.name == toStatus)[0];
        const formData = {
          requestStatus: stat.uuid,
          note: this.rejectNote,
        };
        if (formData.note == "") {
          formData.note = (undefined as unknown) as string;
        }
        await patch_request(this.uuid, formData).then(() => {
          this.defaultState();
          Notif.notif_success("Status Updated !!");
        });
      });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchRequestResponse() {
    try {
      const resp = await get_request_response({
        request_uuid: this.uuid,
        ascending: 1,
      });
      this.requestResponseData = resp.data._embedded.requestResponse;
      this.requestResponseData.forEach((item) => {
        if (item.userProfile) {
          item.userProfile.firstName = fullName(
            item.userProfile.firstName,
            item.userProfile.lastName
          );
        } else {
          item.userProfile!.firstName = "Anonymous"; // eslint-disable-line
        }
        item.createdAt = time_format(item.createdAt, "YYYY-MM-DD hh:mm A");
      });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async doRequestResponse() {
    try {
      const formData = new FormData();
      const temp = this.requestResponse;
      Object.keys(temp).forEach((item) => {
        const key = item as keyof typeof temp;
        if (this.requestResponse[key] != "" && this.requestResponse[key]) {
          formData.append(key, this.requestResponse[key]);
        }
      });
      await post_request_response(formData).then(() => {
        this.fetchRequestResponse();
        this.requestResponse.comment = "";
        this.requestResponse.photo = (null as unknown) as File;
      });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchRequestAttachment() {
    try {
      const resp = await get_request_attachment({ request_uuid: this.uuid })
      this.requestAttachmentList = resp.data._embedded.requestAttachment
    } catch (error) {
      Notif.notif_error_api(error)
    }
  }

  errorImg(index: number) {
    this.requestResponseData[index].response = "/img/imageNotFound.jpg";
  }

  pickaPict() {
    (this.$refs.image as any).$refs.input.click();
  }

  showAssignTicket() {
    this.addTicket = {
      request: this.uuid,
      customer: {
        uuid: this.requestCustomer.uuid,
        name: this.requestCustomer.name,
      },
    };
  }

  defaultState() {
    this.fetchData();
    this.fetchDataTable();
    this.fetchRequestResponse();
    this.fetchRequestAttachment();
    this.rejectDialog = false;
    this.rejectNote = "";
  }

  openPdf(link: string) {
    window.open(link, "_blank");
  }

  /* Life-cycle Methods */

  mounted() {
    this.defaultState();
  }

  @Watch("requestResponse.photo")
  onPickpict(now: File) {
    const f = new FileReader();
    if (now) {
      f.readAsDataURL(this.requestResponse.photo);
      f.onload = (event) => {
        if (!event.target) return;
        this.pictView = event.target.result;
      };
    } else {
      this.pictView = null;
    }
    this.requestResponse.photo = now;
  }

  @Watch("trigger")
  onTrigger(now: trigger) {
    if (now.main == "Request" && now.secondary == this.uuid) {
      this.fetchRequestResponse();
    }
  }
}
