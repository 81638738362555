













































































import { Component, Vue } from "vue-property-decorator";
import { Notif } from "@/store";
import { get_ticket_detail } from "@/api";
import { ticketDetailType } from "@/api/api.types";

@Component({ name: "customer-detail" })
export default class CustomerDetail extends Vue {
  /* Variables */

  uuid = this.$route.params.uuid;

  detailData = {} as ticketDetailType["data"]["customer"];

  customerPost = { lat: 0, lng: 0 };

  tab = null

  /* Computed */
  /* Methods */

  async fetchData() {
    try {
      const resp = await get_ticket_detail(this.uuid);
      this.detailData = resp.data.customer;
      if (this.detailData)
        this.customerPost = {
          lat: parseFloat(this.detailData.latitude),
          lng: parseFloat(this.detailData.longitude),
        };
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  /* Life-cycle Methods */

  mounted() {
    this.fetchData();
  }
}
