



















import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

type dummyItems = {
  dateTime: string;
  ticketNo: string;
  siteName: string;
  caller: string;
  problemDesc: string;
  latUpdate: string;
  lastCall: string;
  panelStat: string;
  stat: string;
};
@Component({ name: "site-tickets" })
export default class SiteTickets extends Vue {
  /* Variables */

  isLoading = false;

  dummyItems = [] as dummyItems[];

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Date / Time Called", value: "dateTime" },
      { text: "Ticket No", value: "ticketNo" },
      { text: "Site Name", value: "siteName" },
      { text: "Caller", value: "caller" },
      { text: "Problem Description", value: "problemDesc" },
      { text: "Lat Update Log", value: "latUpdate" },
      { text: "Last Call Log", value: "lastCall" },
      { text: "Panel Status", value: "panelStat" },
      { text: "Status", value: "stat" },
    ];
  }

  /* Method */

  fetchData() {
    this.isLoading = true;
    for (let x = 0; x < 3; x++) {
      this.dummyItems.push({
        dateTime: "27-12-2021 12:12:00",
        ticketNo: "11586700",
        siteName: "Semesta CNL Resources SDN BHD 00005273",
        caller: "Izzudin",
        problemDesc: "asdasda",
        latUpdate: "",
        lastCall: "",
        panelStat: "",
        stat: "",
      });
    }
    this.isLoading = false;
  }

  toPage(value: dummyItems) {
    console.log(value);
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
  }
}
