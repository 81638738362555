import { AppModule } from "@/shared/types";

import PageNotFound from "../NotFound/PageNotFound.vue";
import UserManagement from "./UserManagement.vue";

const ComplaintsAppModule: AppModule[] = [
    {
        rootPath: '/complaints-app',
        rootIcon: 'mdi-cellphone-message',
        rootName: 'Complaints App',
        authRequired: true,
        layout: "sidebar",
        pages: [
            {
                path: 'user-management',
                name: "User Management",
                component: UserManagement
            },
            {
                path: 'device-abwabwa',
                name: "Device Abwabwa",
                component: PageNotFound
            },
        ]
    }
]

export default ComplaintsAppModule;

