import basic from "./Basic.layout.vue";
import sidebar from "./Sidebar.layout.vue";

const layout = {
    basic,
    sidebar
};

export type AvailableLayoutKeys = keyof typeof layout;
export default layout;
