import router from "@/router"
import { Auth } from "@/store";
import moment from "moment";

/**
 * for router replace,
 * without error message
 * @param value
 */
export const route_replace = (path: string, query?: any) => {
    Object.keys(query).forEach(key => {
        if (query[key] == null || query[key] == "") {
            query[key] = undefined
        }
    })
    router.replace({ path, query: query })
        .catch((error) => {
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
        });
}

export const time_format = (time: string, format?: string) => {
    if (!(format)) format = 'YYYY-MM-DD HH:mm:ss'

    return moment(time).tz(Auth.profile_account.timezone).format(format)
}

export const toTimeStamp = (time: string) => {
    return Date.parse(time) / 1000
}

export const fullName = (firstName: string, lastName: string) => {
    
    return `${firstName ? firstName : firstName} ${lastName ? lastName : ''}`
}