import layout from '@/layout'
import Modules from '@/views/register-module'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = []

Modules.forEach(modules => {
  const topush = modules.map(
    route =>
      ({
        path: route.rootPath,
        component: layout[route.layout],
        meta: { authRequired: route.authRequired || false },
        children: route.pages.map(c => c)
      } as RouteConfig)
  );
  routes.push(...topush);
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
