


































































































































































































































































































































import * as compApi from "@/api";
import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import StatusChip from "@/components/StatusChip.vue";
import AddTicket from "./AddTicket.vue";
import {
  complaintsAttachmentType,
  complaintsDetailType,
  complaintsResponseType,
  complaintsStatusLogType,
  complaintsStatusType,
} from "@/api/api.types";
import { fullName, time_format } from "@/shared/utils";
import { trigger } from "@/store/module/notif.store";

@Component({
  name: "complaints-detail",
  components: { StatusChip, AddTicket },
})
export default class ComplaintsDetail extends Vue {
  /* Constant */

  timeFormat = (time: string, format?: string) => {
    return time_format(time, format);
  };

  /* Variables */

  detailData = {} as complaintsDetailType["data"];

  uuid = this.$route.params.uuid;

  addTicket = (null as unknown) as {
    complain: string;
    customer: { uuid: string; name: string };
  };

  response = {
    complain: this.uuid,
    comment: "",
    photo: (null as unknown) as File,
  };

  pictView = (null as unknown) as string | ArrayBuffer | null;

  complaintStatus = [] as complaintsStatusType["data"]["_embedded"]["complainStatus"];

  detailAddOns = {
    submitted: "",
    photo: [] as { photo: string }[],
  };

  statusLogData = [] as complaintsStatusLogType["data"]["_embedded"]["complainStatusLog"];

  responseData = [] as complaintsResponseType["data"]["_embedded"]["complainResponse"];

  tableData = [] as {
    coloumn1: string;
    coloumn2: string;
  }[];

  photoOverlay = (null as unknown) as string;

  complainCustomer = {} as complaintsDetailType["data"]["customer"];

  customerPost = { lat: 0, lng: 0 };

  attachmentList = [] as complaintsAttachmentType["data"]["_embedded"]["complainAttachment"];

  /* Computed */

  public get complaintsStatLogHeaders(): DataTableHeader[] {
    return [
      { text: "Status", value: "complainStatus" },
      { text: "Date Time", value: "createdAt" },
      { text: "Change By", value: "createdBy.firstName" },
    ];
  }

  public get tableHeaders(): DataTableHeader[] {
    return [
      { text: "Coloumn 1", value: "coloumn1" },
      { text: "Coloumn 1", value: "coloumn1" },
      { text: "", value: "actions" },
    ];
  }

  public get trigger(): trigger {
    return Notif.triggers;
  }

  /* Methods */

  async fetchData() {
    try {
      const resp = await compApi.get_complaints_detail(this.uuid);
      const d = resp.data;
      this.detailData = d;
      this.detailData.createdAt = time_format(d.createdAt);
      this.detailData.updatedAt = time_format(d.updatedAt);
      this.detailAddOns = {
        submitted: fullName(d.userProfile.firstName, d.userProfile.lastName),
        photo: d.photos,
      };
      this.complainCustomer = d.customer;
      if (d.customer) {
        this.customerPost = {
          lat: parseFloat(d.customer.latitude),
          lng: parseFloat(d.customer.longitude),
        };
      }
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchStatusLog() {
    try {
      const resp = await compApi.get_complaints_status_log({
        complain_uuid: this.uuid,
      });
      this.statusLogData = resp.data._embedded.complainStatusLog;
      this.statusLogData.forEach((item) => {
        if (item.createdBy == null) {
          item.createdBy = {
            uuid: "-",
            firstName: "Anonymous",
            lastName: "-",
            phone: "-",
            email: "-",
          };
        } else {
          item.createdBy.firstName = fullName(item.createdBy.firstName, item.createdBy.lastName);
        }
        item.createdAt = time_format(item.createdAt);
      });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchComplaintResponse() {
    try {
      const resp = await compApi.get_complaints_response({
        complain_uuid: this.uuid,
        ascending: 1,
      });
      this.responseData = resp.data._embedded.complainResponse;
      this.responseData.forEach((item) => {
        if (item.userProfile == null) {
          item.userProfile = {
            uuid: "-",
            firstName: "Anonymous",
            lastName: "-",
            phone: "-",
            email: "-",
          };
        } else {
          item.userProfile.firstName = fullName(item.userProfile.firstName, item.userProfile.lastName);
        }
        item.createdAt = time_format(item.createdAt, "YYYY-MM-DD, hh:mm A");
      });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async doComplaintResponse() {
    try {
      if (this.response.comment == "" && !this.response.photo) return;
      const formData = new FormData();
      const tempResponse = this.response;
      Object.keys(this.response).forEach((value) => {
        const keys = value as keyof typeof tempResponse;
        if (this.response[keys] != "" && this.response[keys]) {
          formData.append(value, this.response[keys]);
        }
      });
      await compApi.post_complaints_response(formData).then(() => {
        this.fetchComplaintResponse();
        this.response.photo = (null as unknown) as File;
        this.response.comment = "";
      });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchComplaintStatus() {
    try {
      const resp = await compApi.get_complaints_status({ account: this.detailData.account.uuid });
      return resp.data._embedded.complainStatus;
    } catch (error) {
      Notif.notif_error_api(error);
      return []
    }
  }

  async updateComplaintStatus(statAct: string) {
    try {
      this.fetchComplaintStatus().then(async (resp) => {
        const uuidStatus = resp.filter(
          (item) => item.name == statAct
        )[0].uuid;
        await compApi
          .patch_complaints(this.uuid, {
            complainStatus: uuidStatus,
          })
          .then(() => {
            this.defaultState();
            Notif.notif_success("Status Updated");
          });
      })
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchAttachment() {
    try {
      const resp = await compApi.get_complaints_attachment({
        complain_uuid: this.uuid,
        type: "SR",
      });
      this.attachmentList = resp.data._embedded.complainAttachment;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  showAddTicket() {
    this.addTicket = {
      complain: this.uuid,
      customer: {
        uuid: this.complainCustomer.uuid,
        name: this.complainCustomer.name,
      },
    };
  }

  pickaPict() {
    (this.$refs.image as any).$refs.input.click();
  }

  errorImg(index: number) {
    this.responseData[index].response = "/img/imageNotFound.jpg";
  }

  defaultState() {
    this.fetchData();
    this.fetchStatusLog();
    this.fetchComplaintResponse();
  }

  openPdf(link: string) {
    window.open(link, "_blank");
  }

  handleNotif() {
    if (this.$route.query.notif === "1") {
      if (this.$refs.complaintResp) {
        (this.$refs.complaintResp as any).scrollIntoView({ behavior: 'smooth' })
        // this.$router.replace({ 'query': undefined });
      }
    }
  }

  async readNotif() {
    try {
      await compApi.post_notification_read({ uuid: this.uuid, type: 'Complain' })
    } catch (error) {
      Notif.notif_error_api(error)
    }
  }

  /* Life-cycle Methods */

  mounted() {
    this.defaultState();
    this.fetchAttachment();
    setTimeout(() => { this.handleNotif() }, 1000)
    this.readNotif();
  }

  @Watch("response.photo")
  onPickpict(now: File) {
    const f = new FileReader();
    if (now) {
      f.readAsDataURL(this.response.photo);
      f.onload = (event) => {
        if (!event.target) return;
        this.pictView = event.target.result;
      };
    } else {
      this.pictView = null;
    }
    this.response.photo = now;
  }

  @Watch("trigger")
  onTrigger(now: trigger) {
    if (now.main == "Complaint" && now.secondary == this.uuid) {
      if (!(this.$refs.complaintResp)) return
      this.fetchComplaintResponse();
      (this.$refs.complaintResp as any).scrollIntoView({ behavior: 'smooth' })
    }
  }
}
