























































































import { Component, Vue } from "vue-property-decorator";
import ReportFooter from "@/components/ReportFooter.vue";

@Component({ name: "sms-inbox-report", components: { ReportFooter } })
export default class SMSInboxReport extends Vue {
  /* Variables */

  filters = {
    start_date: (null as unknown) as string,
    end_date: (null as unknown) as string,
    group: (null as unknown) as string,
    instrument: (null as unknown) as string,
    decoded_content: (null as unknown) as string,
    output: (null as unknown) as string,
    message_type: (null as unknown) as string,
  };

  /* Computed */
  /* Methods */

  doDownload(something: string[]) {
    console.log(something);
  }

  /* Life-cycle Methods */
}
