



























































































import { Component, Vue, Watch } from "vue-property-decorator";
import DatePicker from "@/components/DatePicker.vue";
import { DataTableHeader } from "vuetify";
import { Notif } from "@/store";
import { get_account, get_alarm, get_device } from "@/api";
import { alarmType, deviceDetailType } from "@/api/api.types";
import { route_replace } from "@/shared/utils";

type alarmTableType = alarmType["data"]["_embedded"]["alarmLog"];
@Component({
  name: "alarm-log",
  components: { DatePicker },
})
export default class AlarmLog extends Vue {
  /* Variables */

  isLoading = false;

  filter = {
    account_uuid: this.$route.query.account_uuid,
    start_date: this.$route.query.start_date,
    end_date: this.$route.query.end_date,
    event_type: this.$route.query.event_type,
    device_uuid: this.$route.query.device_uuid,
  };

  deviceSearch = (null as unknown) as string;

  date = this.filter.start_date
    ? [this.filter.start_date, this.filter.end_date]
    : [];

  tableItems = [] as alarmTableType;

  page = {
    next: (null as unknown) as string,
    prev: (null as unknown) as string,
  };

  deviceItems = [] as deviceDetailType["data"][];

  typeItem = ["ElectronicFence", "FaceCapture", "FaceCompare", "Alarm"];

  accItem = [] as {
    value: string;
    text: string;
  }[];

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Device", value: "device.name" },
      { text: "Company", value: "account.name" },
      { text: "Channel", value: "channel" },
      { text: "Type", value: "eventType" },
      { text: "Time", value: "localTime" },
      { text: "", value: "actions" },
    ];
  }

  /* Method */

  async fetchData(cursor?: string) {
    this.isLoading = true;
    try {
      if (this.date?.length > 1) {
        this.filter.start_date = this.date[0];
        this.filter.end_date = this.date[1];
      } else {
        this.filter.start_date = (undefined as unknown) as string;
        this.filter.end_date = (undefined as unknown) as string;
      }
      const resp = await get_alarm({ ...this.filter, cursor: cursor });
      this.page.next = resp.data._links.next?.href.split("cursor=")[1];
      this.page.prev = resp.data._links.prev?.href.split("cursor=")[1];
      this.tableItems = resp.data._embedded.alarmLog;
      route_replace(this.$route.path, { ...this.filter, cursor });
    } catch (error) {
      Notif.notif_error_api(error);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchAccount() {
    try {
      const resp = await get_account();
      resp.data._embedded.account.forEach((values) => {
        this.accItem.push({
          value: values.uuid,
          text: values.name,
        });
      });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchDevice(search?: string) {
    try {
      const resp = await get_device({ search });
      this.deviceItems = resp.data._embedded.device;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchAccount();
    this.fetchData(this.$route.query.cursor as string);
  }

  @Watch("deviceSearch")
  onDeviceChange(search: string) {
    if (search) this.fetchDevice(search);
  }
}
