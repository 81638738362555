










































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { Notif } from "@/store";
import { fullName, route_replace } from "@/shared/utils";
import {
  delete_complaints_app,
  get_account,
  get_complaints_app,
  patch_complaints_app,
} from "@/api";
import { accounType, complaintsAppType } from "@/api/api.types";
import AddUser from "./AddUser.vue";

type localAccountType = accounType["data"]["_embedded"]["account"];
@Component({
  name: "user-management",
  components: { AddUser },
})
export default class UserManagement extends Vue {
  /* Variables */

  isLoading = false;

  companyList = [] as localAccountType;

  addUser = false;

  delUser = "";

  filter = {
    search: this.$route.query.search || null,
    account: this.$route.query.account || null,
  };

  tableItems =
    [] as complaintsAppType["data"]["_embedded"]["complainUserProfile"];

  page = {
    page_count: 0,
    page_size: 10,
    page: 0,
  };

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Name", value: "firstName" },
      { text: "Company", value: "account.name" },
      { text: "Email", value: "email" },
      { text: "Role", value: "role" },
      { text: "Phone", value: "phone" },
      { text: "Status", value: "isActive" },
      { text: "", value: "actions" },
    ];
  }

  /* Method */

  async fetchData(noPage = this.page.page) {
    if (noPage == 0) noPage = 1;
    this.isLoading = true;
    try {
      const resp = await get_complaints_app({ ...this.filter, page: noPage });
      const { page, page_size, page_count } = resp.data;
      this.page = { page, page_size, page_count };
      this.tableItems = resp.data._embedded.complainUserProfile;
      this.tableItems.forEach((item) => {
        item.firstName = fullName(item.firstName, item.lastName);
      });
      route_replace(this.$route.path, { ...this.filter, page: noPage });
    } catch (error) {
      Notif.notif_error_api(error);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchCompanyList() {
    try {
      const resp = await get_account();
      this.companyList = resp.data._embedded.account;
      this.companyList.unshift({
        uuid: null as unknown as string,
        name: "All Company",
      } as any); //
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async changeStat(uuid: string, status: boolean) {
    try {
      await patch_complaints_app(uuid, { isActive: status ? 1 : 0 });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async deleteUser() {
    try {
      await delete_complaints_app(this.delUser).then(() => {
        Notif.notif_success("Data Successfully Deleted !");
        this.delUser = "";
        this.fetchData();
      });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
    this.fetchCompanyList();
  }

  @Watch("page.page")
  onPageChange(no: number) {
    if (no) this.fetchData();
  }
}
