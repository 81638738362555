import Vue from 'vue'
import Vuex from 'vuex'
import token_injector from './plugin/token_injector'
import AuthStore from "./module/auth.store";
import NotifStore from "./module/notif.store";
import createPersistedState from "vuex-persistedstate";
import token_refresher from './plugin/token_refresher';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: true,
  state: {},
  mutations: {},
  actions: {},
  modules: { AuthStore, NotifStore },
  plugins: [createPersistedState(), token_injector, token_refresher]
})
