






























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { Notif } from "@/store";
import { fullName, route_replace, time_format } from "@/shared/utils";
import DatePicker from "@/components/DatePicker.vue";
import StatusChip from "@/components/StatusChip.vue";
import ToolTruncate from "@/components/ToolTruncate.vue";
import {
  get_account,
  get_complaints,
  get_complaints_category,
  get_complaints_status,
  get_notification,
} from "@/api";
import {
  complaintsCategoryType,
  complaintsStatusType,
  complaintsType,
} from "@/api/api.types";

@Component({
  name: "complaints-index",
  components: { DatePicker, StatusChip, ToolTruncate },
})
export default class ComplaintsIndex extends Vue {
  /* Variables */

  fullName = fullName;

  isLoading = false;

  filter = {
    complain_id: this.$route.query.complain_id || null,
    start_date: this.$route.query.start_date || null,
    end_date: this.$route.query.end_date || null,
    complain_status_uuid: this.$route.query.complain_status_uuid || null,
    complain_category_uuid: this.$route.query.complain_category_uuid || null,
    account: this.$route.query.account || null,
  };

  statusList = [] as complaintsStatusType["data"]["_embedded"]["complainStatus"];

  categoryList = [] as complaintsCategoryType["data"]["_embedded"]["complainCategory"];

  accountList = [] as { value: string; text: string }[];

  date = this.filter.start_date
    ? [this.filter.start_date, this.filter.end_date]
    : [];

  tableItems = [] as complaintsType["data"]["_embedded"]["complain"];

  // tableItems = [] as tableItemsType

  notification = [] as { length: number; uuid: string }[]

  page = {
    page_count: 0,
    page_size: 1,
    page: 0,
  };

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "ID", value: "complainId" },
      { text: "Sumbitted By", value: "userProfile" },
      { text: "Company", value: "account.name" },
      { text: "Category", value: "complainCategory" },
      { text: "Location", value: "customer" },
      { text: "Description", value: "description" },
      { text: "Status", value: "complainStatus" },
      { text: "Created Time", value: "createdAt" },
      { text: "", value: "notifications" },
      { text: "", value: "actions" },
    ];
  }

  /* Method */

  async fetchData(toPage = this.page.page) {
    if (toPage == 0) toPage = 1;
    this.isLoading = true;
    try {
      if (this.date?.length > 1) {
        this.filter.start_date = this.date[0];
        this.filter.end_date = this.date[1];
      } else {
        this.filter.start_date = (undefined as unknown) as string;
        this.filter.end_date = (undefined as unknown) as string;
      }
      const resp = await get_complaints({ ...this.filter, page: toPage });
      this.tableItems = resp.data._embedded.complain;
      this.tableItems.forEach((item) => {
        item.createdAt = time_format(item.createdAt);
      });
      const { page_count, page_size, page } = resp.data;
      this.page = { page_count, page_size, page };
      route_replace(this.$route.path, { ...this.filter, page: toPage });
      this.fetchNotificationLog();
    } catch (error) {
      Notif.notif_error_api(error);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchStatusData() {
    try {
      const resp = await get_complaints_status({
        order: "order",
        ascending: 1,
      });
      this.statusList = resp.data._embedded.complainStatus;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchCategoryData() {
    try {
      const resp = await get_complaints_category({
        order: "order",
        ascending: 1,
      });
      this.categoryList = resp.data._embedded.complainCategory;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchAccountData() {
    try {
      const resp = await get_account();
      const data = resp.data._embedded.account.map((item) => {
        return { value: item.uuid, text: item.name };
      });
      this.accountList = [
        {
          value: (null as unknown) as string,
          text: "All Company",
        },
        ...data,
      ];
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchNotificationLog() {
    try {
      const resp = await get_notification({ limit: -1, unread: 1, admin: 1 })
      this.notification = []
      this.tableItems.forEach(async (items) => {
        const s = await resp.data._embedded.notification.filter(filter => filter.complain == items.uuid)
        this.notification.push({ length: s.length, uuid: items.uuid })
      })
    } catch (error) {
      Notif.notif_error_api(error)
    }
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
    setInterval(() => {
      this.fetchData();
    }, 60000);
    this.fetchStatusData();
    this.fetchCategoryData();
    this.fetchAccountData();
  }

  @Watch("page.page")
  onPageChange(no: number) {
    if (no) this.fetchData();
  }
}
