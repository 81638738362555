import { AppModule } from "@/shared/types";

import RequestIndex from "./index.vue";
import RequestDetail from "./detail.vue";

const RequestModule: AppModule[] = [
    {
        rootPath: '/request',
        rootIcon: 'mdi-chat-alert',
        rootName: 'Request',
        authRequired: true,
        layout: "sidebar",
        pages: [
            {
                path: '/',
                name: "Request",
                component: RequestIndex
            },
            {
                path: ':uuid',
                name: "Request Details",
                component: RequestDetail
            },
        ]
    }
]

export default RequestModule;