


















































import { me } from "@/api/api.types";
import { fullName } from "@/shared/utils";
import { Auth } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

@Component({ name: "my-profile" })
export default class MyProfile extends Vue {
  /* Variables */

  fullName = fullName;

  item = [
    { case: "case", open: "yes", closed: "no", total: "total" },
    {
      case: "case",
      open: "yes",
      closed: "no",
      total: "total",
      cat: "Prioritize",
    },
    {
      case: "case",
      open: "yes",
      closed: "no",
      total: "total",
      cat: "Prioritize",
    },
  ];

  /* Computed */

  public get profile(): me["data"] {
    return Auth.profile_account;
  }

  public get timeSheetsHeaders(): DataTableHeader[] {
    return [
      { text: "Ticket ID", value: "ticketId" },
      { text: "Serial Number", value: "serialNum" },
      { text: "Status", value: "status" },
      { text: "Hours Worked", value: "hWorked" },
      { text: "Last Update", value: "lastUpdate" },
      { text: "Red Alarm", value: "redAlarm" },
      { text: "Not Attended", value: "notAttend" },
      { text: "Not Action", value: "notAction" },
    ];
  }

  public get ticketStatHeaders(): DataTableHeader[] {
    return [
      { text: "Case", value: "case", groupable: false },
      { text: "Open", value: "open", groupable: false },
      { text: "Closed", value: "closed", groupable: false },
      { text: "Total", value: "total", groupable: false },
      { text: "Categorized By", value: "cat" },
    ];
  }

  /* Methods */

  /* Life-cycle Methods */
}
