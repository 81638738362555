


























import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

type dummyItems = {
  assignee: string;
  phones: string;
  mobileNo: string;
  email: string;
  positions: string;
};
@Component({ name: "assignee-information" })
export default class AssignieeInfo extends Vue {
  /* Variables */

  isLoading = false;

  dummyItems = [] as dummyItems[];

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Assignee", value: "assignee" },
      { text: "Phones", value: "phones" },
      { text: "Mobile No", value: "mobileNo" },
      { text: "Email", value: "email" },
      { text: "Positions", value: "positions" },
    ];
  }

  /* Method */

  fetchData() {
    this.isLoading = true;
    for (let x = 0; x < 3; x++) {
      this.dummyItems.push({
        assignee: "hqadmin",
        phones: "00",
        mobileNo: "6019667344",
        email: "info@xtend.net.my",
        positions: "1st Level Support",
      });
    }
    this.isLoading = false;
  }

  toPage(value: dummyItems) {
    console.log(value);
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
  }
}
