import { AppModule } from "@/shared/types";

import PageNotFound from "../NotFound/PageNotFound.vue";

const MyRecordsModule: AppModule[] = [
    {
        rootPath: '/my-records',
        rootIcon: 'mdi-chart-box',
        rootName: 'My Records',
        authRequired: false,
        layout: "sidebar",
        pages: [
            {
                path: 'my-time-sheets',
                name: "My Time Sheets",
                component: PageNotFound
            },
            {
                path: 'my-ticket-statistic',
                name: "My Ticket Statistic",
                component: PageNotFound
            },
        ]
    }
]

export default MyRecordsModule;