import { AppModule } from "@/shared/types";

import TicketReponse from "./TicketResponse.vue";
import SignalCount from "./SignalCount.vue";
import SignalCountType from "./SignalCountType.vue";
import TicketByAgent from "./TicketByAgent.vue";

import SitesTechgroup from "./SitesTechgroup.vue";
import TicketReport from "./TicketReport.vue";
import TicketReportClient from "./TicketReportClient.vue";
import TicketResponseReport from "./TicketResponseReport.vue";
import RenovationReport from "./RenovationReport.vue";
import ServiceReport from "./ServiceReport.vue";
import TerminationReport from "./TerminationReport.vue";
import PanelReport from "./PanelReport.vue";
import CallListSchedule from "./CallListSchedule.vue";
import AssigneesSchedule from "./AssigneesSchedule.vue";
import HealthcheckReport from "./HealthcheckReport.vue";
import AutoArmReport from "./AutoArmReport.vue";
import DisarmReport from "./DisarmReport.vue";
import SMSInboxReport from "./SMSInboxReport.vue";
import CMSInboxReport from "./CMSInboxReport.vue";
import NewRecordReport from "./NewRecordReport.vue";
import WhatsappInboxReport from "./WhatsappInboxReport.vue";
import MessageSentReport from "./MessageSentReport.vue";
import AssetList from "./AssetList.vue";
import GovernanceSummary from "./GovernanceSummary.vue";
import WhatsappInbox from "./WhatsappInbox.vue";
import CMSInbox from "./CMSInbox.vue";

import PageNotFound from "../NotFound/PageNotFound.vue";

const ReportingModule: AppModule[] = [
    {
        rootPath: '/report',
        rootIcon: 'mdi-book',
        rootName: 'Report',
        authRequired: true,
        layout: "sidebar",
        pages: [
            {
                path: 'ticket-response',
                name: "Ticket Response",
                component: TicketReponse
            },
            {
                path: 'signal-count',
                name: 'Signal Count',
                component: SignalCount
            },
            {
                path: 'signal-count-type',
                name: 'Signal Count by Type',
                component: SignalCountType
            },
            {
                path: 'ticket-by-agent',
                name: 'Ticket by Agent',
                component: TicketByAgent
            },
            {
                path: 'site-techgroup',
                name: "Site By Tech Group",
                component: SitesTechgroup
            },
            {
                path: 'ticket-report',
                name: "Ticket Report",
                component: TicketReport
            },
            {
                path: 'ticket-report-client',
                name: "Ticket Report Client",
                component: TicketReportClient
            },
            {
                path: 'ticket-response-report',
                name: "Ticket Response Report",
                component: TicketResponseReport
            },
            {
                path: 'renovation-report',
                name: 'Renovation Report',
                component: RenovationReport
            },
            {
                path: 'service-report',
                name: 'Service Report',
                component: ServiceReport
            },
            {
                path: 'termination-report',
                name: 'Termination Report',
                component: TerminationReport
            },
            {
                path: 'panel-report',
                name: 'Panel Report',
                component: PanelReport
            },
            {
                path: 'call-list-schedules',
                name: 'Call List Schedules',
                component: CallListSchedule
            },
            {
                path: 'assignees-schedules',
                name: 'Assignees Schedules',
                component: AssigneesSchedule
            },
            {
                path: 'healthcheck-report',
                name: 'Healthcheck Report',
                component: HealthcheckReport
            },
            {
                path: 'auto-arm-report',
                name: 'Auto Arm Report',
                component: AutoArmReport
            },
            {
                path: 'disarm-report',
                name: 'Disarm Report',
                component: DisarmReport
            },
            {
                path: 'sms-inbox-report',
                name: 'SMS Inbox Report',
                component: SMSInboxReport
            },
            {
                path: 'whatsapp-inbox',
                name: 'Whatsapp Inbox',
                component: WhatsappInbox
            },
            {
                path: 'cms-inbox-report',
                name: 'CMS Inbox Report',
                component: CMSInboxReport
            },
            {
                path: 'cms-inbox',
                name: 'CMS Inbox',
                component: CMSInbox
            },
            {
                path: 'new-accounts-report',
                name: 'New Accounts Report',
                component: NewRecordReport
            },
            {
                path: 'ticket-statistic-report',
                name: 'Ticket Statistic Report',
                component: PageNotFound
            },
            {
                path: 'whatsapp-inbox-report',
                name: 'Whatsapp Inbox Report',
                component: WhatsappInboxReport
            },
            {
                path: 'message-sent-report',
                name: 'Message-Sent-Report',
                component: MessageSentReport
            },
            {
                path: 'assets-list',
                name: 'Assets List',
                component: AssetList
            },
            {
                path: 'governance-summary',
                name: 'Governance/CM-PM-Hardening Summary',
                component: GovernanceSummary
            },
        ]
    }
]

export default ReportingModule;