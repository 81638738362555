const config = {
    // Branding
    APP_NAME: process.env.VUE_APP_NAME,
    APP_TITLE: process.env.VUE_APP_TITLE,
    APP_COPYRIGHT: process.env.VUE_APP_COPYRIGHT,
    APP_COPYRIGHT_URL: process.env.VUE_APP_COPYRIGHT,

    // API
    APP_SECRET: process.env.VUE_APP_SECRET,
    APP_ID: process.env.VUE_APP_ID,
    API_URL: process.env.VUE_APP_API_URL,

    // Applications settings
    APP_REFRESH_INTERFAL: Number(process.env.VUE_APP_REFRESH_INTERFAL),

    // External Services
    GOOGLE_MAP_API: process.env.VUE_APP_GOOGLE_MAP_API,

    // Firebase
    FIREBASE_CONFIG: {
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID,
        measurementId: process.env.VUE_APP_FIREBASE_MEASU,
    }

    // FIREBASE_CONFIG: {
    //     apiKey: "AIzaSyDLiaE--ywcxVD4YadmGkOgF0k89iCNQKk",
    //     authDomain: "test-notification-fc8fe.firebaseapp.com",
    //     projectId: "test-notification-fc8fe",
    //     storageBucket: "test-notification-fc8fe.appspot.com",
    //     messagingSenderId: "963490014332",
    //     appId: "1:963490014332:web:00f22bf52a6887743655f9",
    //     measurementId: "G-DSJ0YZF9N1"
    // }
};

export default config;