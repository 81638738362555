









































































































import {
  get_account,
  get_complaints_app_detail,
  patch_complaints_app,
  post_complaints_app,
} from "@/api";
import { accounType } from "@/api/api.types";
import { VuetifyForm } from "@/shared/types";
import { eightChar, emailForm, required } from "@/shared/validations";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({ name: "add-user" })
export default class AddUser extends Vue {
  /* Constants */

  @Prop({ default: null }) readonly value!: string | boolean;

  /* Variables */

  Val = { required, eightChar, emailForm };

  formData = {
    firstName: "",
    lastName: "",
    account: "",
    role: "",
    address: "",
    phone: "",
    email: "",
    password: "",
  };

  companyList = [] as accounType["data"]["_embedded"]["account"];

  roleList = [
    { value: "user", text: "User" },
    { value: "officer", text: "Officer" },
  ];

  confPass = "";

  edit = false;

  /* Computed */

  public get confirmPass() {
    return (
      (this.confPass && this.formData.password === this.confPass) ||
      "Password don't match"
    );
  }

  /* Methods */

  async fetchData(uuid: string) {
    try {
      const resp = await get_complaints_app_detail(uuid);
      const { firstName, lastName, role, phone, email, address } = resp.data;
      this.formData = {
        firstName,
        lastName,
        account: resp.data.account.uuid,
        role,
        phone,
        email,
        address,
        password: (undefined as unknown) as string,
      };
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchCompany() {
    try {
      const resp = await get_account();
      this.companyList = resp.data._embedded.account;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  /* Life-cycle Methods */

  @Emit("submit")
  async submit() {
    if (!(this.$refs.formUser as VuetifyForm).validate()) return;
    try {
      if (this.edit) {
        await patch_complaints_app(this.value as string, {
          ...this.formData,
          role: "tenant",
        }).then(() => {
          Notif.notif_success("User Successfully Edited !");
          this.close();
        });
      } else {
        await post_complaints_app({ ...this.formData }).then(() => {
          Notif.notif_success("User Successfully Added !");
          this.close();
        });
      }
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  mounted() {
    this.fetchCompany();
  }

  @Emit("input")
  close() {
    this.edit = false;
    (this.$refs.formUser as VuetifyForm).reset();
    return false;
  }

  @Watch("value")
  onValueChange(now: boolean | string) {
    if (typeof now == "string") {
      this.fetchData(now);
      this.edit = true;
    }
  }
}
