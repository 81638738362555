






































import { get_alarm_detail } from "@/api";
import { alarmDetailType } from "@/api/api.types";
import { Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component({ name: "alarm-detail" })
export default class AlarmDetail extends Vue {
  /* Variables */

  data = {} as alarmDetailType["data"];

  /* Computed */

  public get pict(): string | undefined {
    if (this.data) {
      return this.data.eventInfos ? this.data.eventInfos[0].filePath : "";
    } else {
      return "";
    }
  }

  /* Methods */

  async fetchData() {
    try {
      const resp = await get_alarm_detail(this.$route.params.uuid);
      this.data = resp.data;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  /* Life-cycle Methods */

  mounted() {
    this.fetchData();
  }
}
