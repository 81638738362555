














































































import { Component, Vue } from "vue-property-decorator";

@Component({ name: "issue-create" })
export default class IssueCreate extends Vue {
  /* Variable */
  /* Computed */
  /* Method */
  /* Life-cycle Method */
}
