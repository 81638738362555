import { AppModule } from "@/shared/types";

import PageNotFound from "./PageNotFound.vue";

const PageNotFoundModule: AppModule[] = [
    {
        rootPath: '*',
        authRequired: false,
        layout: "basic",
        pages: [
            {
                path: '',
                name: "Page Not Found",
                component: PageNotFound
            },
        ]
    }
]

export default PageNotFoundModule;