import { API, authentications, get_profile, revoke } from "@/api";
import { me } from "@/api/api.types";
import firebase from "firebase/app";
import "@firebase/messaging";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import endpoints from "@/api/api.constants";

type AuthResp = {
    access_token: string;
    expires_in: number;
    refresh_token: string;
}

@Module({ name: "AuthStore" })
export default class AuthStore extends VuexModule {

    private accessToken = (null as unknown) as string;
    private expiresIn = (null as unknown) as number;
    private refreshToken = (null as unknown) as string;
    private profile = {} as me["data"];

    /* Getters */

    public get is_login(): boolean {
        return !!(this.expiresIn) && Date.now() < this.expiresIn
    }

    public get access_token(): string {
        return this.accessToken
    }

    public get expires_in(): number {
        return this.expiresIn
    }

    public get refresh_token(): string {
        return this.refreshToken
    }

    public get profile_account(): me["data"] {
        return this.profile
    }

    /* Mutations */

    @Mutation
    public store_auth(data: AuthResp) {
        this.accessToken = data.access_token;
        this.expiresIn = Date.now() + data.expires_in * 1000;
        this.refreshToken = data.refresh_token;
    }

    @Mutation
    public discard_auth(revoked: boolean) {
        if (revoked) {
            this.accessToken = (null as unknown) as string;
            this.expiresIn = 0;
            this.refreshToken = (null as unknown) as string;
        }
    }

    @Mutation
    public set_profile(data: me["data"]) {
        this.profile = data
    }

    /* Actions */

    @Action({ commit: 'store_auth', rawError: true })
    public async Login(payload: { username: string; password: string }): Promise<AuthResp> {
        const resp = await authentications(payload.username, payload.password);
        this.context.dispatch('firebaseInit')

        const { access_token, expires_in, refresh_token } = resp.data;
        return { access_token, expires_in, refresh_token };
    }

    @Action({ commit: 'discard_auth', rawError: true })
    public async Logout() {
        const resp = await revoke(this.accessToken)
        this.context.commit("set_profile", {})
        return resp.data.revoked
    }

    @Action({ commit: 'set_profile', rawError: true })
    public async getProfile() {
        const resp = await get_profile()
        return resp.data
    }

    @Action({ commit: '' })
    public firebaseInit() {

        navigator.serviceWorker.register(`firebase-messaging-sw.js`).then(() => {
            Notification.requestPermission().then(() => {
                firebase.messaging().getToken().then((token) => {
                    API.post(endpoints.MOBILESTATE, { firebaseId: token })
                })
            })
        }).catch((err) => {
            console.log('Something', location);
            console.log(err);
        })

    }
}
