


























































































































































































































































































































































import {
  get_customer_escalation,
  get_ticket_detail,
  get_ticket_file,
  get_ticket_history,
  get_ticket_log,
  get_ticket_tracking,
  post_pabx_callout,
} from "@/api";
import {
  customerInfoEscalation,
  ticketDetailType,
  ticketFileType,
  ticketHistoryType,
  ticketLogType,
} from "@/api/api.types";
import { time_format } from "@/shared/utils";
import { Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import StatusChip from "@/components/StatusChip.vue";

const TicketActions = () => import("./TicketActions.vue");

type markerType = {
  positions: { lat: number; lng: number };
  icon: string;
  detail: { name: string; detail: string };
};

@Component({ name: "ticket-detail", components: { StatusChip, TicketActions } })
export default class TicketDetail extends Vue {
  /* Variables */

  uuid = this.$route.params.uuid;

  detailData = {} as ticketDetailType["data"];

  historyData = [] as ticketHistoryType["data"]["_embedded"]["ticketHistory"];

  noteData = [] as ticketLogType["data"]["_embedded"]["ticketLog"];

  fileData = [] as ticketFileType["data"]["_embedded"]["ticketFile"];

  customerEscal = [] as customerInfoEscalation["data"];

  customerData = null as ticketDetailType["data"]["customer"];

  photoOverlay = null as unknown as string;

  infoWindow = {
    position: { lat: 0, lng: 0 },
    name: "",
    address: "",
    id: "",
    IsOpen: false,
    options: { pixelOffset: { width: 0, height: -35 } },
  };

  marker = [] as markerType[];

  track = {
    path: [] as { lat: number; lng: number }[],
    geodesic: true,
    strokeColor: "#43A047",
    strokeOpacity: 2,
    strokeWeight: 5,
  };

  setActions = { actions: "reopen", uuid: null as unknown as string };

  /* Computed */

  public get building(): markerType {
    const post = {
      positions: {
        lat: 0,
        lng: 0,
      },
      icon: "/img/place.png",
      detail: {
        name: "",
        detail: "",
      },
    };
    if (this.detailData) {
      if (this.detailData.customer) {
        post.positions = {
          lat: parseFloat(this.detailData.customer.latitude),
          lng: parseFloat(this.detailData.customer.longitude),
        };
        post.detail = {
          name: this.detailData.customer.name,
          detail: this.detailData.customer.address,
        };
      }
    }
    return post;
  }

  public get noteHeaders(): DataTableHeader[] {
    return [
      { text: "Date Time", value: "createdAt" },
      { text: "Created By", value: "createdBy" },
      { text: "Message", value: "message" },
    ];
  }

  /* Methods */

  async fetchData() {
    try {
      const resp = await get_ticket_detail(this.uuid);
      this.detailData = resp.data;
      if (this.detailData.customer) {
        this.fetchEscalation(this.detailData.customer?.uuid);
        this.customerData = this.detailData.customer;
      }
      this.fetchHistory();
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchEscalation(uuid: string) {
    try {
      const resp = await get_customer_escalation(uuid);
      this.customerEscal = resp.data;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchHistory() {
    try {
      const resp = await get_ticket_history({
        ticket_uuid: this.uuid,
      });
      this.historyData = resp.data._embedded.ticketHistory;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchLog() {
    try {
      const resp = await get_ticket_log({
        ticket_uuid: this.uuid,
      });
      this.noteData = resp.data._embedded.ticketLog;
      this.noteData.forEach((item) => {
        item.createdAt = time_format(item.createdAt, "YYYY-MM-DD hh:mm A");
      });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchFile() {
    try {
      const resp = await get_ticket_file({
        ticket_uuid: this.uuid,
      });
      this.fileData = resp.data._embedded.ticketFile;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  getInfoWindows(
    postions: { lat: number; lng: number },
    detail: { name: string; detail: string }
  ) {
    this.infoWindow = {
      position: postions,
      name: detail.name,
      address: detail.detail,
      id: "track",
      IsOpen: true,
      options: this.infoWindow.options,
    };
  }

  async getTrack() {
    try {
      const locations = [] as { lat: number; lng: number }[];
      const resp = await get_ticket_tracking({
        ticket_uuid: this.uuid,
      });
      if (resp.data._embedded.ticketTracking) {
        resp.data._embedded.ticketTracking.forEach((values) => {
          locations.push({
            lat: parseFloat(values.latitude),
            lng: parseFloat(values.longitude),
          });
        });

        if (locations.length > 1) {
          const cons = this.detailData.ticketStatus !== "In Progress";
          this.marker.push({
            positions: {
              lat: locations[cons ? 0 : locations.length - 1].lat,
              lng: locations[cons ? 0 : locations.length - 1].lng,
            },
            detail: {
              name: cons ? "Start Position" : "In Progress",
              detail: this.detailData.assignedUser,
            },
            icon: "/img/user-start.png",
          });
          this.countBonds(
            [this.marker[0], this.building],
            this.$refs.mapping as any
          );
          if (cons) {
            this.marker.push({
              positions: {
                lat: locations[locations.length - 1].lat,
                lng: locations[locations.length - 1].lng,
              },
              detail: { name: "End Position", detail: "" },
              icon: "/img/user-finish.png",
            });
            this.countBonds(this.marker, this.$refs.mapping as any);
          }
          this.track.path = locations;
        }
      }
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  countBonds(technicians: markerType[], mapObject: any) {
    let bounds = new google.maps.LatLngBounds(); // eslint-disable-line
    for (let i = 0; i < technicians.length; i++) {
      if (technicians[i] !== null) {
        bounds.extend({
          lat: technicians[i].positions.lat,
          lng: technicians[i].positions.lng,
        });
      }
    }
    mapObject.fitBounds(bounds);
  }

  doAction(actions: "reopen" | "close" | "reassign") {
    this.setActions = { actions, uuid: this.uuid };
  }

  async doCallPABX(phone: string) {
    try {
      await post_pabx_callout({ tid: this.uuid, phone: phone });
      Notif.notif_success('Call in Process...')
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  /* Life-cycle Methods */

  mounted() {
    this.fetchData();
    this.fetchLog();
    this.fetchFile();
    this.getTrack();
  }
}
