














































































































import { Component, Vue } from "vue-property-decorator";
import ReportFooter from "@/components/ReportFooter.vue";

@Component({ name: "disarm-report", components: { ReportFooter } })
export default class DisarmReport extends Vue {
  /* Variables */

  filters = {
    start_date: (null as unknown) as string,
    end_date: (null as unknown) as string,
    zone_type: (null as unknown) as string,
    location: (null as unknown) as string,
    password_type: (null as unknown) as string,
    company: (null as unknown) as string,
    ticket_status: (null as unknown) as string,
    ticket_no: (null as unknown) as string,
    output: (null as unknown) as string,
  };

  /* Computed */
  /* Methods */

  doDownload(something: string[]) {
    console.log(something);
  }

  /* Life-cycle Methods */
}
