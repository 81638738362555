

















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "vertical-progress" })
export default class VerticalProgress extends Vue {
  /* Constants */

  @Prop({ default: "100px" }) readonly height!: string;

  @Prop({ default: "primary" }) readonly color!: string;

  @Prop({ required: true }) readonly value!: string | number;

  @Prop({ default: null }) readonly max!: string | number;

  /* Computed */

  public get filler(): number {
    if (!this.max) return Number(this.value);
    return (Number(this.value) / Number(this.max)) * 100;
  }
}
