
















































































import { get_technician, get_ticket_category, post_ticket } from "@/api";
import { technicianType, ticketCategoryType } from "@/api/api.types";
import { VuetifyForm } from "@/shared/types";
import { fullName } from "@/shared/utils";
import { required } from "@/shared/validations";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

type valueType = {
  complain: string;
  request: string;
  customer: {
    uuid: string;
    name: string;
  };
};
@Component({ name: "add-ticket" })
export default class AddTicket extends Vue {
  /* Constants */

  // @Prop({ default: false }) readonly value!: boolean;

  @Prop({ default: null }) readonly value!: valueType;

  private VALIDATION = required;

  /* Variables */

  formData = {
    complaint: undefined as unknown as string,
    request: undefined as unknown as string,
    customer: "",
    assignedUser: "",
    ticketCode: "",
    ticketCategory: "",
    device: "",
    problem: "",
  };

  customerName = "";

  codeList = [
    "AC FAILED",
    "AUTO ARM FAILED",
    "BATTERY LOW",
    "BOARD PROBLEM",
    "BOARD RESET",
    "BREAK IN",
    "CORRECTIVE MAINTENANCE",
    "DEVICE DISABLED",
    "DEVICE FAULTY",
    "DISMANTLE",
    "Disarming Alert",
    "FAULT COMPLAINT (IWK)",
    "FAULTY",
    "HANDOVER PROCESS",
    "HEALTH CHECK FAILED",
    "IWK COMPLAINT",
    "MASSIVE ZONE TRIGGERS",
    "NO SIGNAL",
    "NULL",
    "OTHERS",
    "PADLOCK ISSUE",
    "PANEL OFFLINE",
    "PLANNED PREVENTIVE MAINTENANCE SERVICE",
    "Panel Box",
    "REPAIRING",
    "RF SUPERVISION LOSS",
    "RONDA",
    "SERVICING",
    "SIMCARD DISABLED",
    "SITE CHECKING",
    "SITE TESTING & PROGRAMMING",
    "TEST",
    "TICKETS",
    "Treatment",
    "VIDEO LOST",
    "ZONE DISABLED",
    "ZONE TAMPER",
    "ZONE TRIGGERS",
  ];

  techSearch = "";

  technicianList = [] as technicianType["data"]["_embedded"]["technician"];

  ticketCatSearch = "";

  ticketCatList =
    [] as ticketCategoryType["data"]["_embedded"]["ticketCategory"];

  /* Computed */

  /* Methods */

  @Emit("submit")
  async submit() {
    if (!(this.$refs.ticketForm as VuetifyForm).validate()) return;
    try {
      await post_ticket(this.formData).then(() => {
        Notif.notif_success("Ticket sucessfully assigned !");
        this.close();
      });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchTechnician() {
    try {
      const resp = await get_technician(this.techSearch);
      if (resp.data._embedded.technician.length > 0) {
        this.technicianList = resp.data._embedded.technician;
        this.technicianList.forEach((item) => {
          item.firstName = fullName(item.firstName, item.lastName);
        });
      }
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchTicketCat() {
    try {
      const resp = await get_ticket_category({ search: this.ticketCatSearch });
      this.ticketCatList = resp.data._embedded.ticketCategory;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  /* Life-cycle Methods */

  @Emit("input")
  close() {
    (this.$refs.ticketForm as VuetifyForm).reset();
    this.formData.request = undefined as unknown as string;
    this.formData.complaint = undefined as unknown as string;
    return null;
  }

  @Watch("techSearch")
  onTechSearchChange(something: string) {
    if (something) this.fetchTechnician();
  }

  @Watch("value")
  onValueChange(something: valueType) {
    if (something) {
      if (something.complain) this.formData.complaint = something.complain;
      if (something.request) this.formData.request = something.request;
      this.formData.customer = something.customer.uuid;
      this.customerName = something.customer.name;
      this.fetchTicketCat();
    }
  }
}
