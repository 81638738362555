/**
 * checks if the field has been filled,
 * otherwise return error message
 * @param value
 */
export const required = (value: string): true | string => !!value || 'Required'

/**
 * this field can't be less than 8 char,
 * otherwise return error message
 * @param value
 */
export const eightChar = (value: string): boolean | string =>
    (value && value.length >= 8 || 'Min 8 Character')

/**
 * this field must contain format (**@**.**),
 * otherwise return error message
 * @param value
 */
export const emailForm = (value: string): boolean | string => {
    // eslint-disable-next-line
    const val = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return value && val.test(value.toLowerCase()) || 'Please insert the correct email'
}