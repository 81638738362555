






























































































































import { Component, Vue } from "vue-property-decorator";

@Component({ name: "call-add" })
export default class CallAdd extends Vue {}
