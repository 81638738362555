




























































import { Component, Vue } from "vue-property-decorator";
import ReportFooter from "@/components/ReportFooter.vue";

@Component({ name: "auto-arm-report", components: { ReportFooter } })
export default class AutoArmReport extends Vue {
  /* Variables */

  filters = {
    start_date: (null as unknown) as string,
    end_date: (null as unknown) as string,
    location: (null as unknown) as string,
    report_type: (null as unknown) as string,
  };

  /* Computed */
  /* Methods */

  doDownload(something: string[]) {
    console.log(something);
  }

  /* Life-cycle Methods */
}
