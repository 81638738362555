import axios from "axios";
import * as types from "./api.types";
import url from "./api.constants";
import config from "@/shared/config";

export const API = axios.create({
    baseURL: config.API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
});

// let interceptor: number | undefined;
let interceptor = (null as unknown) as number

export const eject_token = () => {
    if (interceptor != null) {
        API.interceptors.request.eject(interceptor)
        interceptor = (null as unknown) as number
    }
}

export const inject_token = (access_token: string, txt?: string) => {
    if (!access_token) return
    eject_token();
    interceptor = API.interceptors.request.use(resq => {
        resq.headers.Authorization = `Bearer ${access_token}`;
        console.log(resq.headers.Authorization, txt ? txt : "");
        return resq;
    })
}

/* Account API */

export const authentications = async (username: string, password: string) => {
    const data = JSON.stringify({
        username,
        password,
        grant_type: 'password',
        client_secret: config.APP_SECRET,
        client_id: config.APP_ID
    })

    const response: types.auth = await API.post(url.AUTH, data);
    return response;
}

export const refresh_auth = async (refreshToken: string) => {
    const data = JSON.stringify({
        grant_type: 'refresh_token',
        client_secret: config.APP_SECRET,
        client_id: config.APP_ID,
        refresh_token: refreshToken
    });

    const resp: types.auth = await API.post(url.AUTH, data);
    return resp;
}

export const revoke = async (access_token: string) => {
    const data = JSON.stringify({
        token_type_hint: "access_token",
        token: access_token
    })

    const resp = await API.post(url.REVOKE, data)
    return resp
}

export const get_profile = async () => {
    const resp: types.me = await API.get(url.ME);
    return resp;
}

/* Tickets API */

export const get_ticket = async (param: any) => {
    const resp: types.ticketType = await API.get(url.TICKET, { params: { ...param } })
    return resp
}

export const get_ticket_detail = async (uuid: string) => {
    const resp: types.ticketDetailType = await API.get(url.TICKET + "/" + uuid)
    return resp
}

export const post_ticket = async (formData: {}) => {
    const resp: types.ticketType = await API.post(url.TICKET, formData)
    return resp
}

/* Ticket Actions API */
export const post_ticket_actions = async (formData: types.ticketActionsForm, actions: 'reopen' | 'close' | 'reassign') => {
    const resp = await API.post(`${url.TICKET_ACTIONS}.${actions}`, formData)
    return resp
}

/* Ticket-Category API */

export const get_ticket_category = async (params?: any) => {
    const resp: types.ticketCategoryType = await API.get(url.TICKET_CATEGORY, { params: params })
    return resp
}

/* Tickets-History API */

export const get_ticket_history = async (params: any) => {
    const resp: types.ticketHistoryType = await API.get(url.TICKET_HISTORY, { params: params })
    return resp
}

/* Ticket-Log API */
export const get_ticket_log = async (params: any) => {
    const resp: types.ticketLogType = await API.get(url.TICKET_LOG, { params: params })
    return resp
}

/* Ticket-Tracking API */
export const get_ticket_tracking = async (params: any) => {
    const resp: types.ticketTrackingType = await API.get(url.TICKET_TRACKING, { params: params })
    return resp
}

/* Ticket-File API */
export const get_ticket_file = async (params: any) => {
    const resp: types.ticketFileType = await API.get(url.TICKET_FILE, { params: params })
    return resp
}

/* Account API */

export const get_account = async (params?: any) => {
    const resp: types.accounType = await API.get(url.ACCOUNT, { params: params })
    return resp
}

/* Alarm API */

export const get_alarm = async (params?: any) => {
    const resp: types.alarmType = await API.get(url.ALARM, { params: params })
    return resp
}

export const get_alarm_detail = async (uuid: string) => {
    const resp: types.alarmDetailType = await API.get(url.ALARM + "/" + uuid)
    return resp
}

/* Device API */

export const get_device = async (params?: any) => {
    const resp: types.deviceType = await API.get(url.DEVICE, { params: params })
    return resp
}

/* Zone-Trigger API */

export const get_zone_trigger = async (params?: any) => {
    const resp: types.zoneTriggerType = await API.get(url.ZONETRIGGER, { params: params })
    return resp
}

export const get_zone_trigger_detail = async (uuid: string) => {
    const resp: types.zoneTriggerDetailType = await API.get(url.ZONETRIGGER + "/" + uuid)
    return resp
}

/* Contact List API */

export const get_contact_list = async (params?: any) => {
    const resp: types.contactListType = await API.get(url.CONTACT_LIST, { params: params })
    return resp
}

/* Tech-Group API */

export const get_tech_group = async (params?: any) => {
    const resp: types.techGroupType = await API.get(url.TECH_GROUP, { params: params })
    return resp
}

/* Complaints API */

export const get_complaints = async (params?: any) => {
    const resp: types.complaintsType = await API.get(url.COMPLAINTS, { params: params })
    return resp
}

export const get_complaints_detail = async (uuid: string) => {
    const resp: types.complaintsDetailType = await API.get(url.COMPLAINTS + "/" + uuid)
    return resp
}

export const get_complaints_status = async (params?: any) => {
    const resp: types.complaintsStatusType = await API.get(url.COMPLAINTS_STATUS, { params: params })
    return resp
}

export const get_complaints_category = async (params?: any) => {
    const resp: types.complaintsCategoryType = await API.get(url.COMPLAINTS_CATEGORY, { params: params })
    return resp
}

export const get_complaints_status_log = async (params?: any) => {
    const resp: types.complaintsStatusLogType = await API.get(url.COMPLAINTS_STATUS_LOG, { params: params })
    return resp
}

export const get_complaints_response = async (params?: any) => {
    const resp: types.complaintsResponseType = await API.get(url.COMPLAINTS_RESPONSE, { params: params })
    return resp
}

export const post_complaints_response = async (formData: FormData) => {
    const resp: types.complaintsResponsePost = await API.post(url.COMPLAINTS_RESPONSE, formData)
    return resp
}

export const patch_complaints = async (uuid: string, formData: any) => {
    const resp = await API.patch(url.COMPLAINTS + "/" + uuid, formData)
    return resp
}

export const get_complaints_attachment = async (params?: {}) => {
    const resp: types.complaintsAttachmentType = await API.get(url.COMPLAINTS_ATTACHMENT, { params: params })
    return resp
}

/* Request API */

export const get_request = async (params?: any) => {
    const resp: types.requestType = await API.get(url.REQUEST, { params: params })
    return resp
}

export const get_request_detail = async (uuid: string) => {
    const resp: types.requestDetailType = await API.get(url.REQUEST + '/' + uuid)
    return resp
}

export const get_request_status = async (params?: any) => {
    const resp: types.requestStatusType = await API.get(url.REQUEST_STATUS, { params: params })
    return resp
}

export const get_request_category = async (params?: any) => {
    const resp: types.requestCategoryType = await API.get(url.REQUEST_CATEGORY, { params: params })
    return resp
}

export const get_request_status_log = async (params?: any) => {
    const resp: types.requestStatusLogType = await API.get(url.REQUEST_STATUS_LOG, { params: params })
    return resp
}

export const patch_request = async (uuid: string, formData: any) => {
    const resp = await API.patch(url.REQUEST + "/" + uuid, formData)
    return resp
}

export const get_request_response = async (params?: any) => {
    const resp: types.requestResponseType = await API.get(url.REQUEST_RESPONSE, { params: params })
    return resp
}

export const post_request_response = async (formData: FormData) => {
    const resp: types.requestResponseType = await API.post(url.REQUEST_RESPONSE, formData)
    return resp
}

export const get_request_attachment = async (params?: any) => {
    const resp: types.requestAttachmentType = await API.get(url.REQUEST_ATTACHMENT, { params: params })
    return resp
}

/* Technician API */

export const get_technician = async (q?: string) => {
    const resp: types.technicianType = await API.get(url.TECHNICIAN, { params: { q } })
    return resp
}

/* ComplaintsApp API */

export const get_complaints_app = async (params?: {}) => {
    const resp: types.complaintsAppType = await API.get(url.COMPLAINTS_APP, { params: params })
    return resp
}

export const get_complaints_app_detail = async (uuid: string) => {
    const resp: types.complaintsAppDetailType = await API.get(url.COMPLAINTS_APP + '/' + uuid)
    return resp
}

export const post_complaints_app = async (formData: types.complaintsAppPostType) => {
    const resp: types.complaintsAppDetailType = await API.post(url.COMPLAINTS_APP, formData)
    return resp
}

export const patch_complaints_app = async (uuid: string, formData: types.complaintsAppPatchType) => {
    const resp = await API.patch(url.COMPLAINTS_APP + '/' + uuid, formData)
    return resp
}

export const delete_complaints_app = async (uuid: string) => {
    await API.delete(url.COMPLAINTS_APP + '/' + uuid)
}

/* Add Ticket API */
export const get_cms_code = async (params?: {}) => {
    const resp: types.cmsCodeType = await API.get(url.CMS_CODE, { params: params })
    return resp
}

export const get_master_companies = async (params?: {}) => {
    const resp: types.masterCompaniesType = await API.get(url.MASTER_COMPANY, { params: params })
    return resp
}

export const get_site_group = async (params?: {}) => {
    const resp: types.siteGroupType = await API.get(url.SITE_GROUP, { params: params })
    return resp
}

export const get_ecalation_list = async (params?: {}) => {
    const resp: types.escalationListType = await API.get(url.ESCALATION_LIST, { params: params })
    return resp
}

export const get_cms_team = async (params?: {}) => {
    const resp: types.cmsTeamType = await API.get(url.CMS_TEAM, { params: params })
    return resp
}

export const get_cms_status = async (params?: {}) => {
    const resp: types.cmsStatus = await API.get(url.CMS_STATUS, { params: params })
    return resp;
}

export const get_cms_site = async (params?: {}) => {
    const resp: types.cmsSiteType = await API.get(url.CMS_SITE, { params: params })
    return resp
}

export const post_cms_site = async (formData?: {}) => {
    const resp: types.cmsSiteDetailType = await API.post(url.CMS_SITE, formData)
    return resp
}

/* Notification API */

export const get_notification = async (params?: any) => {
    const resp: types.notificationType = await API.get(url.NOTIFICATION, { params: params })
    return resp
}

export const post_notification_read = async (form: { uuid: string; type: 'Complain' | 'Request' }) => {
    const resp = await API.post(url.NOTIFICATION_READ, form)
    return resp
}

/* Customer Tabs API */

export const get_customer_asset = async (params?: Record<any, string>) => {
    const resp: types.customerInfoAssetType = await API.get(url.CUSTOMER_INFO_ASSET, { params })
    return resp
}

export const get_customer_id = async (uuid: string) => {
    const resp: types.customerInfoIdType = await API.get(url.CUSTOMER_INFO_ID + '/' + uuid)
    return resp
}

export const get_customer_escalation = async (uuid: string) => {
    const resp: types.customerInfoEscalation = await API.get(url.CUSTOMER_INFO_ESCALATION + '/' + uuid)
    return resp
}

export const get_customer_tech = async (params: { tech_group_uuid: string }) => {
    const resp = await API.get(url.CUSTOMER_INFO_TECH, { params })
    return resp
}

export const get_customer_layout = async (uuid: string) => {
    const resp = await API.get(url.CUSTOMER_LAYOUT + '/' + uuid)
    return resp
}

export const get_custom_near_tech = async (params?: Record<any, string>) => {
    const resp: types.customNearTechType = await API.get(url.CUSTOM_NEARBY_TECH, { params })
    return resp
}

export const get_custom_near_site = async (params?: Record<any, string>) => {
    const resp: types.customNearSiteType = await API.get(url.CUSTOM_NEARBY_SITE, { params })
    return resp
}

/* PABX Callout */

export const post_pabx_callout = async (formData: { tid: string; phone: string }) => {
    const resp: types.pabxCalloutResp = await API.post(url.PABX_CALLOUT, formData)
    return resp
}