






















































































































































































































































































































































































import {
  get_customer_asset,
  get_customer_escalation,
  get_customer_id,
  get_customer_layout,
  get_customer_tech,
  post_pabx_callout,
} from "@/api";
import {
  customerInfoAssetType,
  customerInfoEscalation,
  customerInfoIdType,
  customerLayoutType,
} from "@/api/api.types";
import config from "@/shared/config";
import { Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";

const PhotoView = () => import("@/components/PhotoView.vue");
const NearbyTech = () => import("./SiteInfoTabs/NearbyTech.vue");
const NearbySite = () => import("./SiteInfoTabs/NearbySite.vue");

@Component({
  name: "site-information",
  components: { PhotoView, NearbyTech, NearbySite },
})
export default class SiteInfo extends Vue {
  /* Variables */

  uuid = this.$route.params.uuid;

  detailData = {} as customerInfoIdType["data"];

  detailCaller = [] as customerInfoIdType["data"]["customerCall"];

  customerPost = { name: "", position: { lat: 0, lng: 0 } };

  tab = null;

  // Customer Info
  asset = [] as customerInfoAssetType["data"]["_embedded"]["AtacCustomerAsset"];
  escalation = [] as customerInfoEscalation["data"];
  tech = [] as Array<any>;

  layout = {} as customerLayoutType["data"];
  photoOverlay = null as string | null;

  /* Computed */

  /* Methods */

  async fetchDetail() {
    try {
      const resp = await get_customer_id(this.uuid);
      this.detailData = resp.data;
      this.customerPost = {
        name: this.detailData.name,
        position: {
          lat: parseFloat(this.detailData.latitude),
          lng: parseFloat(this.detailData.longitude),
        },
      };
      this.detailCaller = resp.data.customerCall;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchAsset() {
    try {
      const resp = await get_customer_asset({ customer_uuid: this.uuid });
      this.asset = resp.data._embedded.AtacCustomerAsset;
    } catch (error) {
      if (error.response.status == 404) return;
      Notif.notif_error_api(error);
    }
  }
  async fetchEscalation() {
    try {
      const resp = await get_customer_escalation(this.uuid);
      this.escalation = resp.data;
    } catch (error) {
      if (error.response.status == 404) return;
      Notif.notif_error_api(error);
    }
  }
  async fetchTech() {
    try {
      const resp = await get_customer_tech({
        tech_group_uuid: this.uuid,
      });
      this.asset = resp.data._embedded.AtacCustomerAsset;
    } catch (error) {
      if (error.response.status == 404) return;
      Notif.notif_error_api(error);
    }
  }

  async fetchInfo() {
    this.fetchAsset();
    this.fetchEscalation();
    this.fetchTech();
  }

  async fetchLayout() {
    try {
      const resp = await get_customer_layout(this.uuid);
      this.layout = resp.data;
    } catch (error) {
      if (error.response.status !== 404) Notif.notif_error_api(error);
    }
  }

  async fetchLayoutImg() {
    this.photoOverlay =
      config.API_URL + "/api/v1/customer-atac-layout-plan-image/" + this.uuid;
  }

  async doCallPABX(phone: string) {
    try {
      await post_pabx_callout({ tid: this.uuid, phone: phone });
      Notif.notif_success("Call in Process...");
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  /* Life-cycle Methods */

  mounted() {
    this.fetchDetail();
    this.fetchInfo();
    this.fetchLayout();
  }
}
