import { AppModule } from "@/shared/types";

import Dashboard from "./Dashboard.vue";

const DashModule: AppModule[] = [
    {
        rootPath: '/',
        rootName: 'Dashboard',
        rootIcon: 'mdi-chart-donut',
        layout: 'sidebar',
        authRequired: true,
        pages: [
            {
                path: '',
                name: 'Dashboard',
                component: Dashboard
            }
        ]
    }
];

export default DashModule;