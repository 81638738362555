






































































import { Component, Vue } from "vue-property-decorator";
import ReportFooter from "@/components/ReportFooter.vue";

@Component({ name: "termination-report", components: { ReportFooter } })
export default class TerminationReport extends Vue {
  /* Variables */

  filters = {
    start_date: (null as unknown) as string,
    end_date: (null as unknown) as string,
    postcode: (null as unknown) as string,
    company_type: (null as unknown) as string,
    state: (null as unknown) as string,
  };

  /* Computed */
  /* Methods */

  doDownload(something: string[]) {
    console.log(something);
  }

  /* Life-cycle Methods */
}
