import { AppModule } from "@/shared/types";

import Login from "./Login.vue";

const LoginModule: AppModule[] = [
    {
        rootPath: '/',
        authRequired: false,
        layout: "basic",
        pages: [
            {
                path: 'login',
                name: "Login",
                component: Login
            },
        ]
    }
]

export default LoginModule;