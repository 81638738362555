








































import { Component, Vue } from "vue-property-decorator";
import HorizontalProgress from "@/components/HorizontalProgress.vue";
import { DataTableHeader } from "vuetify";

type dummyItems = {
  siteCode: string;
  siteName: string;
  bar1: number;
  bar2: number;
};
@Component({
  name: "signal-count",
  components: { HorizontalProgress },
})
export default class SignalCount extends Vue {
  /* Variables */

  isLoading = false;

  dummyItems = [] as dummyItems[];

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Site Code", value: "siteCode" },
      { text: "Site Name", value: "siteName" },
      { text: "No of Signal", value: "bar1", width: "50%" },
    ];
  }

  /* Method */

  fetchData() {
    this.isLoading = true;
    for (let x = 0; x < 3; x++) {
      this.dummyItems.push({
        siteCode: "Column 1 Item " + x,
        siteName: "Column 2 Item " + x,
        bar1: 10,
        bar2: 5,
      });
    }
    this.isLoading = false;
  }

  toPage(value: dummyItems) {
    console.log(value);
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
  }
}
