























































import { Component, Vue } from "vue-property-decorator";

@Component({ name: "edit-profile" })
export default class EditProfile extends Vue {}
