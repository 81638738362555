






























































































































import { Component, Vue } from "vue-property-decorator";
import ReportFooter from "@/components/ReportFooter.vue";

@Component({ name: "ticket-report", components: { ReportFooter } })
export default class TicketReport extends Vue {
  /* Variables */

  filters = {
    start_date: (null as unknown) as string,
    end_date: (null as unknown) as string,
    site_group: (null as unknown) as string,
    category: (null as unknown) as string,
    panel: (null as unknown) as string,
    job_code: (null as unknown) as string,
    call_log: (null as unknown) as string,
    multi_zones: (null as unknown) as string,
    ticket_type: (null as unknown) as string,
    agent_name: (null as unknown) as string,
    ticket_status: (null as unknown) as string,
  };

  /* Computed */
  /* Methods */

  doDownload(something: string[]) {
    console.log(something);
  }

  /* Life-cycle Methods */
}
