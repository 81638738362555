import { AppModule } from "@/shared/types";

import MyProfile from "./MyProfile.vue";
import EditProfile from "./EditProfile.vue";

const ProfileModule: AppModule[] = [
    {
        rootPath: '/profile',
        rootName: 'Profile',
        authRequired: true,
        layout: "sidebar",
        pages: [
            {
                path: 'my-profile',
                name: "My Profile",
                hidePage: true,
                component: MyProfile
            },
            {
                path: 'edit-profile',
                name: "Edit My Profile",
                hidePage: true,
                component: EditProfile
            },
        ]
    }
]

export default ProfileModule;