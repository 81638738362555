


















































































































import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { Notif } from "@/store";
import { get_account, get_ticket } from "@/api";
import { ticketType } from "@/api/api.types";
import DatePicker from "@/components/DatePicker.vue";
import ToolTruncate from "@/components/ToolTruncate.vue";
import StatusChip from "@/components/StatusChip.vue";
import { route_replace } from "@/shared/utils";

type ticketTableType = ticketType["data"]["_embedded"]["ticket"];
@Component({
  name: "ticket-index",
  components: { DatePicker, ToolTruncate, StatusChip },
})
export default class TicketIndex extends Vue {
  /* Variables */

  isLoading = false;

  tableItems = [] as ticketTableType;

  filter = {
    status: this.$route.query.status || null,
    ticket_id: this.$route.query.ticket_id || null,
    start_date: this.$route.query.start_date || null,
    end_date: this.$route.query.end_date || null,
    location_id: this.$route.query.location_id || null,
    account_uuid: this.$route.query.account_uuid || null,
  };

  date = this.filter.start_date
    ? [this.filter.start_date, this.filter.end_date]
    : [];

  page = {
    next: (null as unknown) as string,
    prev: (null as unknown) as string,
  };

  statItem = [
    {
      value: null,
      text: "All Status",
    },
    "Open",
    "ReOpen",
    "Pending",
    "In Progress",
    "Resolved",
    "Closed",
  ];

  accItem = [
    {
      value: (null as unknown) as string,
      text: "All Company",
    },
  ];

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Ticket ID", value: "ticketId" },
      { text: "Code", value: "ticketCode" },
      { text: "Problem", value: "problem" },
      { text: "Customer Name", value: "customer.name" },
      { text: "Assign Technician", value: "assignedUser" },
      { text: "Location ID", value: "customer.locationId" },
      { text: "Status", value: "ticketStatus" },
      { text: "Ticket Date", value: "ticketCreatedTime" },
      { text: "", value: "actions" },
    ];
  }

  /* Method */

  async fetchData(cursor?: string) {
    this.isLoading = true;
    try {
      if (this.date !== null) {
        if (this.date.length > 1) {
          this.filter.start_date = this.date[0];
          this.filter.end_date = this.date[1];
        }
      } else {
        this.filter.start_date = null;
        this.filter.end_date = null;
      }
      const resp = await get_ticket({ ...this.filter, cursor });
      this.page.next = resp.data._links.next?.href.split("cursor=")[1];
      this.page.prev = resp.data._links.prev?.href.split("cursor=")[1];
      this.tableItems = resp.data._embedded.ticket;
      route_replace(this.$route.path, { ...this.filter, cursor });
    } catch (error) {
      Notif.notif_error_api(error);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchAccount() {
    try {
      const resp = await get_account(null);
      resp.data._embedded.account.forEach((values) => {
        this.accItem.push({
          value: values.uuid,
          text: values.name,
        });
      });
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchAccount();
    this.fetchData(this.$route.query.cursor as string);
  }
}
