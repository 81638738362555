import { AppModule } from "@/shared/types";

import AddSite from "./AddSite.vue";
import SearchSite from "./SearchSite.vue";

import PageNotFound from "../NotFound/PageNotFound.vue";

const SiteManagementModule: AppModule[] = [
    {
        rootPath: '/site-management',
        rootIcon: 'mdi-web',
        rootName: 'Site Management',
        authRequired: true,
        layout: "sidebar",
        pages: [
            {
                path: 'idp-info',
                name: "IPD Info",
                component: PageNotFound
            },
            {
                path: 'add-user',
                name: "Add Site",
                component: AddSite
            },
            {
                path: 'address-book',
                name: "Address Book",
                component: PageNotFound
            },
            {
                path: 'search-caller',
                name: "Search Caller",
                component: PageNotFound
            },
            {
                path: 'search-site',
                name: "Search Site",
                component: SearchSite
            },
        ]
    }
]

export default SiteManagementModule;