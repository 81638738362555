





























import { toTimeStamp } from "@/shared/utils";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({ name: "single-date" })
export default class SingleDate extends Vue {
  @Prop({ default: null }) readonly value!: string;

  @Prop({ default: "Search By Date" }) readonly label!: string;

  @Prop({ default: false }) readonly outlined!: boolean;

  @Prop({ default: false }) readonly unixFormat!: boolean;


  private dateMenu = false;

  dates = ""

  // private get date() {
  //     return this.value || "";
  // }

  // private set date(value: string) {
  //   this.$emit("input", value);
  //   this.$emit("unixTime", toTimeStamp(value));
  // }

  @Watch('dates')
  test(now: string) {
    if (this.unixFormat) {
      this.$emit("input", toTimeStamp(now));
    } else {
      this.$emit("input", now);
    }
  }
}
