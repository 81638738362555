





















// import config from "@/shared/config";
// import firebase from "firebase/app";
import { Notif } from "@/store";
import "firebase/messaging";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({ name: "notification-bubble" })
export default class NotificationBubble extends Vue {
  // notifData = {
  //   message: "",
  //   username: "",
  // };

  @Prop({ required: true }) readonly notifData!: {
    body: string;
    title: string;
    type: string;
    uuid: string;
  } | null;

  notify = false;

  notifClick() {
    this.notify = false;
    if (!this.notifData) return;
    this.$router.push({
      name: `${this.notifData.type} Details`,
      params: { uuid: this.notifData.uuid },
      query: { notif: '1'}
    });
    console.log(this.notifData.type, "Details");
  }

  // receiveMessage() {
  //   try {
  //     if (!firebase.app.length) {
  //       firebase.initializeApp(config.FIREBASE_CONFIG);
  //       console.log("tolol");
  //     } else {
  //       console.log("goblok", firebase.app.length  );
  //     }
  //     firebase.messaging().onMessage((payload) => {
  //       // console.log("Message received. ", payload);
  //       this.notifData = payload.data;
  //       this.notify = true;
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  // created() {
  //   this.receiveMessage();
  // }

  @Watch("notifData")
  onNotifDataChange(
    now: {
      body: string;
      title: string;
      type: string;
      uuid: string;
    } | null
  ) {
    if (now) {
      this.notify = true;
      // console.log("uuid", now.uuid);
      Notif.refresh_trigger({ main: now.type, secondary: now.uuid });
    }
  }
}
