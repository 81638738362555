


























import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

type dummyItems = {
  panelTime: string;
  cmsName: string;
  zone: string;
  channel: string;
  alarmCode: string;
  content: string;
  zoneName: string;
  serverTime: string;
};
@Component({ name: "cms-inbox" })
export default class CMSInbox extends Vue {
  /* Variables */

  isLoading = false;

  dummyItems = [] as dummyItems[];

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Panel Time", value: "panelTime" },
      { text: "CMS Unit Name", value: "cmsName" },
      { text: "Zone", value: "zone" },
      { text: "Channel", value: "channel" },
      { text: "Alarm Code", value: "alarmCode" },
      { text: "Content", value: "content" },
      { text: "Zone Name", value: "zoneName" },
      { text: "Server Date / Time", value: "serverTime" },
    ];
  }

  /* Method */

  fetchData() {
    this.isLoading = true;
    for (let x = 0; x < 3; x++) {
      this.dummyItems.push({
        panelTime: "18-01-2021 12:18:21",
        cmsName: "Masjid Rantau Panjang",
        zone: "0",
        channel: "8",
        alarmCode: "3994",
        content: "Video Recovery",
        zoneName: "NOT DEFINED",
        serverTime: "18-01-2021 12:18:26",
      });
    }
    this.isLoading = false;
  }

  toPage(value: dummyItems) {
    console.log(value);
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
  }
}
