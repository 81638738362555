
























import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

type dummyItems = {
  column1: string;
  column2: string;
  bar1: number;
  bar2: number;
};
@Component({ name: "call-schedule" })
export default class CallSchedule extends Vue {
  /* Variables */

  isLoading = false;

  dummyItems = [] as dummyItems[];

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "ID", value: "id" },
      { text: "Call List", value: "callList" },
      { text: "Zone", value: "zone" },
      { text: "Alarm Codes", value: "alarmCodes" },
      { text: "Start Date", value: "startDate" },
      { text: "End Date", value: "endDate" },
      { text: "Start Time", value: "startTime" },
      { text: "End Time", value: "endTime" },
      { text: "Actions", value: "actions" },
    ];
  }

  /* Method */

  fetchData() {
    this.isLoading = true;
    for (let x = 0; x < 3; x++) {
      this.dummyItems.push({
        column1: 'Column 1 Item ' + x,
        column2: 'Column 2 Item ' + x,
        bar1: 10,
        bar2: 5,
      });
    }
    this.isLoading = false;
  }

  toPage(value: dummyItems) {
    console.log(value);
  }

  /* Life-cycle Method */

}
