























import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

type dummyItems = {
  id: string;
  dateTime: string;
  history: string;
  review: string;
  update: string;
  by: string;
  actions: string;
};
@Component({ name: "site-history" })
export default class SiteHistory extends Vue {
  /* Variables */

  isLoading = false;

  dummyItems = [] as dummyItems[];

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "ID", value: "id" },
      { text: "Date / Time", value: "dateTime" },
      { text: "History Text", value: "history" },
      { text: "Review Update", value: "review" },
      { text: "Update", value: "update" },
      { text: "By", value: "by" },
      { text: "Actions", value: "actions" },
    ];
  }

  /* Method */

  fetchData() {
    this.isLoading = true;
    for (let x = 0; x < 3; x++) {
      this.dummyItems.push({
        id: '27417',
        dateTime: '27-12-2021 17:20:00',
        history: 'Site Modified : Site Code change from SMR to RD25P',
        review: '-',
        update: '27-12-2021 17:29:00',
        by: 'sakkilah',
        actions: ''
      });
    }
    this.isLoading = false;
  }

  toPage(value: dummyItems) {
    console.log(value);
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
  }
}
