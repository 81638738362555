import { AppModule } from "@/shared/types";
import DashModule from "./Dashboard/dashboard.module";
import Issue from "./Issue/issue.module";
import TicketModule from "./Ticket/ticket.module";
import ReportingModule from "./Reporting/reporting.module";
import ProfileModule from "./Profile/profile.module";
import LoginModule from "./Account/account.module";
import AlarmModule from "./IPCAlarmLog/alarm.module";
import PageNotFoundModule from "./NotFound/pageNotFound.module";
import SiteManagementModule from "./SiteManagement/siteManagement.module";
import PanelManagementModule from "./PanelManagement/panelManagement.module";
import MyRecordsModule from "./MyRecords/myRecords.module";
import ComplaintsModule from "./Complaints/complaints.module";
import RequestModule from "./Request/request.module";
import ComplaintsAppModule from "./ComplaintsApp/complaintsApp.module";

const Modules: AppModule[][] = [
    DashModule,
    Issue,
    TicketModule,
    AlarmModule,
    ComplaintsModule,
    RequestModule,
    ComplaintsAppModule,
    SiteManagementModule,
    PanelManagementModule,
    MyRecordsModule,
    ReportingModule,
    ProfileModule,
    LoginModule,
    PageNotFoundModule
];

export default Modules;