



































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "date-picker" })
export default class DatePicker extends Vue {
  @Prop({ default: null }) readonly value!: string[];

  private dateMenu = false;

  private get dates() {
    return this.value || [];
  }

  private set dates(value: string[]) {
    this.$emit("input", value);
  }

  private get datePick(): string | null {
    if (this.dates && this.dates.length > 1) {
      if (this.dates[0] > this.dates[1]) {
        const test = [this.dates[0], this.dates[1]]
        this.dates[0] = test[1]
        this.dates[1] = test[0]
      }
      return `${this.dates[0]} ~ ${this.dates[1]}`;
    } else {
      return null;
    }
  }

  private dateRule() {
    if (this.dates.length > 1) {
      console.log(this.dates.length);

      this.dateMenu = false;
    }
  }
}
