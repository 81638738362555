import { refresh_auth } from "@/api"
import { Store } from "vuex";

const refresher = (store: Store<any>, token_refresh: string) => {
    return setTimeout(async () => {
        const resp = refresh_auth(token_refresh);
        const { access_token, expires_in, refresh_token } = (await resp).data
        store.commit("store_auth", { access_token, expires_in, refresh_token })
    }, 300000)
}

const token_refresher = (store: Store<any>) => {
    const token_refresher = store.getters["refresh_token"]
    let timeOut: undefined | number

    if (token_refresher) timeOut = refresher(store, token_refresher)

    store.subscribe((mutation) => {
        if (mutation.type === "store_auth") {
            timeOut = void (timeOut && clearTimeout(timeOut))
            timeOut = refresher(store, mutation.payload.refresh_token)
        } else if (mutation.type === "discard_auth") {
            timeOut = void (timeOut && clearTimeout(timeOut))
        }
    })
}

export default token_refresher;