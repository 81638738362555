








































import { Component, Vue } from "vue-property-decorator";
import ReportFooter from "@/components/ReportFooter.vue";

@Component({ name: "call-list-schedule", components: { ReportFooter } })
export default class CallListSchedule extends Vue {
  /* Variables */

  filters = {
    site_group: (null as unknown) as string,
    site_code: (null as unknown) as string,
  };

  /* Computed */
  /* Methods */

  doDownload(something: string[]) {
    console.log(something);
  }

  /* Life-cycle Methods */
}
