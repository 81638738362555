




































































































































































































































































































































import {
  get_cms_code,
  get_cms_status,
  get_cms_team,
  get_ecalation_list,
  get_master_companies,
  get_site_group,
  post_cms_site,
} from "@/api";
import {
  cmsCodeType,
  cmsStatus,
  cmsTeamType,
  escalationListType,
  masterCompaniesType,
  siteGroupType,
} from "@/api/api.types";
import { Notif } from "@/store";
import { Component, Vue, Watch } from "vue-property-decorator";
import SingleDate from "@/components/SingleDate.vue";
import { toTimeStamp } from "@/shared/utils";
import { required } from "@/shared/validations";
import { VuetifyForm } from "@/shared/types";

type cmsCode = cmsCodeType["data"]["_embedded"]["codes"];
type listType = { value: number; text: string }[];
@Component({ name: "add-user", components: { SingleDate } })
export default class AddUser extends Vue {
  /* Constants */

  private VALIDATION = [required];

  /* Variables */

  search = {
    escal: "",
    tech: "",
    repair: "",
    complaint: "",
  };

  formData = {
    companyName: "",
    locationName: "",
    address: "",
    postcode: "",
    stateCode: (null as unknown) as string,
    telno: "",
    faxno: "",
    etype: (null as unknown) as string,
    companyMasterId: (null as unknown) as string,
    region: (null as unknown) as string,
    ctype: (null as unknown) as string,
    ctypeMain: (null as unknown) as string,
    servicesOpted: "",
    locationId: (null as unknown) as string,
    techGroupId: (null as unknown) as string,
    repairGroupId: (null as unknown) as string,
    complaintGroupId: (null as unknown) as string,
    internalCommunicationEmail: "",
    internalCommunicationTelegram: "",
    broadcastTicketUpdates: "",
    comments: "",
    terminationDate: 0,
    handoverDate: 0,
    renovationSdate: 0,
    renovationEdate: 0,
    poRate: 300,
    latitude: "",
    longitude: "",
    accessWorkday: (null as unknown) as string,
    accessWorkhour: (null as unknown) as string,
    highrisk: 0,
    gmtTimeszone: "+8",
    forAccountOf: (null as unknown) as string,
    paymentStatus: (null as unknown) as string,
    statusId: (null as unknown) as string,
  };

  toTimestamp = toTimeStamp;

  date = "";

  customerList = [] as masterCompaniesType["data"]["_embedded"]["companiesMaster"];

  stateList = [] as listType;

  customerTypeList = [] as listType;

  regionList = [] as listType;

  workingDaysList = [] as listType;

  workingHrList = [] as listType;

  paymentStatList = [] as listType;

  forAccountList = [] as listType;

  siteGroupList = [] as siteGroupType["data"]["_embedded"]["companyTypes"];

  escalationList = [] as escalationListType["data"]["_embedded"]["locations"];

  techTeamList = [] as cmsTeamType["data"]["_embedded"]["groups"];

  repairTeamList = [] as cmsTeamType["data"]["_embedded"]["groups"];
  
  complaintTeamList = [] as cmsTeamType["data"]["_embedded"]["groups"];

  statusList = [] as cmsStatus["data"]["_embedded"]["cstatus"];

  broadcastList = [
    { value: "0", text: "DISABLED" },
    { value: "4", text: "ZONE TRIGGER - External Update" },
  ];

  serviceItem = [
    { text: "CCTV", value: "1" },
    { text: "ALARM", value: "2" },
    { text: "ATTENDANCE", value: "3" },
    { text: "CCTV Outright", value: "11" },
    { text: "ALARM Outright", value: "12" },
    { text: "ATTENDANCE Outright", value: "13" },
    { text: "CCTV Monitoring Comprehensive", value: "31" },
    { text: "RENTAL", value: "21" },
    { text: "EM LOCK", value: "22" },
    { text: "RMS", value: "23" },
    { text: "AVLS", value: "24" },
    { text: "Outright Cxcess", value: "41" },
    { text: "Outright Access Control", value: "42" },
  ];

  /* Computed */

  public get timeZoneList(): string[] {
    const time = [] as string[];
    for (let x = -11; x <= 12; x++) {
      if (x > 0) {
        time.push(`+${x.toString()}`);
      } else {
        time.push(x.toString());
      }
    }
    return time;
  }

  /* Methods */

  async fetchCostumer() {
    try {
      const resp = await get_master_companies();
      this.customerList = resp.data._embedded.companiesMaster;
      this.customerList.unshift({
        uuid: (null as unknown) as string,
        company: "No customer selected",
      } as any);
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchCmsCode(code_type: string, label: string, var_l: string) {
    try {
      const resp = await get_cms_code({ type: code_type });
      const list_var = [
        { value: (null as unknown) as number, text: `No ${label} selected` },
      ];
      resp.data._embedded.codes.forEach((item) => {
        list_var.push({ value: item.uuid, text: item.desc });
      });
      // eslint-disable-next-line
      // @ts-ignore
      this[var_l] = list_var;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchGroup() {
    try {
      const resp = await get_site_group();
      this.siteGroupList = resp.data._embedded.companyTypes;
      this.siteGroupList.unshift({
        uuid: (null as unknown) as string,
        types: "No Group selected",
      } as any);
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchEscalationList(name: string) {
    try {
      const resp = await get_ecalation_list({ name });
      this.escalationList = resp.data._embedded.locations;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchCmsTeam(name: string, v: string) {
    try {
      const resp = await get_cms_team({ name });
      // eslint-disable-next-line
      // @ts-ignore
      this[v] = resp.data._embedded.groups
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchStatus() {
    try {
      const resp = await get_cms_status();
      this.statusList = resp.data._embedded.cstatus;
      this.statusList.unshift({
        uuid: (null as unknown) as string,
        status: "No status selected",
      } as any);
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async submit() {
    try {
      const siteForm = this.$refs.siteForm as VuetifyForm;
      if (siteForm.validate()) {
        this.formData.servicesOpted = this.formData.servicesOpted.toString();
        await post_cms_site({ ...this.formData });
        siteForm.reset();
      }
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  test(test: string) {
    console.log("test", test);
  }

  /* Life-cycle Methods */

  mounted() {
    this.fetchCostumer();
    this.fetchCmsCode("S", "state", "stateList");
    this.fetchCmsCode("E", "customer type", "customerTypeList");
    this.fetchCmsCode("ZON", "region", "regionList");
    this.fetchCmsCode("AWD", "working day", "workingDaysList");
    this.fetchCmsCode("AWH", "working hour", "workingHrList");
    this.fetchCmsCode("PAY", "payment stat", "paymentStatList");
    this.fetchCmsCode("A", "account", "forAccountList");
    this.fetchGroup();
    this.fetchStatus();
  }

  @Watch("search.escal")
  onEscalChange(now: string) {
    this.fetchEscalationList(now);
  }

  @Watch("search.tech")
  onTechChange(now: string) {
    this.fetchCmsTeam(now, 'techTeamList')
  }

  @Watch("search.repair")
  onRepairChange(now: string) {
    this.fetchCmsTeam(now, 'repairTeamList');
  }

  @Watch("search.complaint")
  onComplaintChange(now: string) {
    this.fetchCmsTeam(now, 'complaintTeamList');
  }
}
