































































































































import { Auth, Notif } from "@/store";
import Modules from "@/views/register-module";
import { Component, Vue, Watch } from "vue-property-decorator";
import NotificationBar from "@/components/NotificationBar.vue";
import NotificationBubble from "@/components/NotificationBubble.vue";
import { me } from "@/api/api.types";
import { AppModule } from "@/shared/types";
import firebase from "firebase/app";
import "@firebase/messaging";
import { fullName } from "@/shared/utils";
import { get_notification } from "@/api";
import { trigger } from "@/store/module/notif.store";

type Link = {
  text: string;
  href: string;
  hide?: boolean;
  icon?: string;
  children?: Link[];
};
@Component({
  name: "sidebar-layout",
  components: { NotificationBar, NotificationBubble },
})
export default class SideLayout extends Vue {
  /* Constants */

  fullName = fullName;

  /* Variables */

  active = true;

  notifData = {
    body: "",
    title: "",
    type: "",
    uuid: "",
  };

  notifTotal = 0

  /* Computed */

  public get profile(): me | {} {
    return Auth.profile_account;
  }

  public get drawerLink(): Link[] {
    const pages = [] as Link[];
    Modules.forEach((root) => {
      root.forEach((page) => {
        if (!(page.rootName && page.rootIcon)) return;
        const childPages = [] as AppModule["pages"];
        page.pages.forEach((values) => {
          if (!values.path.includes(":")) {
            childPages.push(values);
          }
        });

        const tempPage: Link = {
          text: page.rootName,
          icon: page.rootIcon,
          href: childPages.length > 1 ? " - " : page.rootPath,
          children:
            childPages.length > 1
              ? childPages.map((child) => ({
                text: child.name as string,
                icon: child.icon as string,
                hide: child.hidePage,
                href: page.rootPath + "/" + child.path,
              }))
              : undefined,
        };
        pages.push(tempPage);
      });
    });
    return pages;
  }

  public get trigger(): trigger {
    return Notif.triggers
  }

  /* Methods */

  public async logout() {
    try {
      await Auth.Logout().finally(() => {
        localStorage.clear();
        this.$router.replace({ name: "Login" });
        window.location.reload();
      });
    } catch (error) {
      console.log("Error logout : ", error);
    }
  }

  receiveMessage() {
    try {
      firebase.messaging().onMessage((payload) => {
        // console.log("Message received. ", payload);
        this.notifData = payload.data;
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchNotificationLog() {
    try {
      const resp = await get_notification({ limit: -1, unread: 1, admin: 1 })
      this.notifTotal = resp.data.total_items
    } catch (error) {
      Notif.notif_error_api(error)
    }
  }

  /* Life-cycle Methods */

  mounted() {
    Auth.getProfile();
    // firebase.initializeApp(config.FIREBASE_CONFIG);
    this.receiveMessage();
    this.fetchNotificationLog();
    setInterval(() => this.fetchNotificationLog(), 60000)
  }

  @Watch('trigger')
  onTrigger(now: trigger) {
    if (now) this.fetchNotificationLog()
  }
}
