














import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

type dummyItems = {
  date: string;
  ticketNo: string;
  siteName: string;
  caller: string;
  problem: string;
  mPassed: string;
  updated: string;
  pStatus: string;
  status: string;
  uuid: string;
};
@Component({ name: "issue-open" })
export default class IssueOpen extends Vue {
  /* Variables */

  isLoading = false;

  dummyItems = [] as dummyItems[];

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Date/Time Called", value: "date" },
      { text: "Ticket No", value: "ticketNo" },
      { text: "Site Name", value: "siteName" },
      { text: "Caller", value: "caller" },
      { text: "Problem Description", value: "problem" },
      { text: "Minutes Passed", value: "mPassed" },
      { text: "Updated", value: "updated" },
      { text: "Panel Status", value: "pStatus" },
      { text: "Status", value: "status" },
    ];
  }

  /* Method */

  fetchData() {
    this.isLoading = true;
    for (let x = 0; x < 3; x++) {
      this.dummyItems.push({
        date: "18-04-2019 10:30:29",
        ticketNo: "5002027",
        siteName: "Seksyen",
        caller: "SPV AZMIR",
        problem: "SPV AZMIR informed wire been cut off. Need team to check",
        mPassed: "907831",
        updated: "18-04-2019 10:30:29",
        pStatus: "",
        status: "ok",
        uuid: "asdsad",
      });
    }
    this.isLoading = false;
  }

  toPage(value: dummyItems) {
    console.log(value);
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
  }
}
