























































































import { Component, Vue } from "vue-property-decorator";

@Component({ name: "call-info" })
export default class CallInfo extends Vue {}
