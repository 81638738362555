


































































import { Component, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { Notif } from "@/store";
import { route_replace, time_format } from "@/shared/utils";
import { get_zone_trigger } from "@/api";
import { zoneTriggerDetailType, zoneTriggerType } from "@/api/api.types";
import ToolTruncate from "@/components/ToolTruncate.vue";

@Component({
  name: "open-trigger-index",
  components: { ToolTruncate },
})
export default class OpenTriggerIndex extends Vue {
  /* Variables */

  isLoading = false;

  filter = {
    mode: this.$route.query.mode || null,
  };

  triggerModeList = [
    { value: null, text: "-" },
    { value: "L", text: "Live" },
    { value: "M", text: "Maintenance" },
  ];

  tableItems = [] as zoneTriggerType["data"]["_embedded"]["zoneTrigger"];

  page = {
    next: (null as unknown) as string,
    prev: (null as unknown) as string,
  };

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Date/Time Called", value: "createdAt", width: '161px' },
      { text: "Ticket No", value: "uuid" },
      { text: "Site Name", value: "company.companyName" },
      { text: "Caller", value: "contact.fullName" },
      { text: "Problem Description", value: "description" },
      { text: "Panel Time", value: "panelTime", width: '161px' },
      { text: "Updated", value: "dateModified", width: '161px' },
      { text: "Panel Status", value: "pStatus" },
      { text: "Status", value: "status" },
    ];
  }

  /* Method */

  async fetchData(cursor?: string | (string | null)[]) {
    this.isLoading = true;
    try {
      const resp = await get_zone_trigger({ type: "open", cursor });
      this.page = {
        next: resp.data._links.next?.href.split("cursor=")[1],
        prev: resp.data._links.prev?.href.split("cursor=")[1],
      };
      this.tableItems = resp.data._embedded.zoneTrigger;
      this.tableItems.forEach(item => {
        item.createdAt = time_format(item.createdAt)
        item.dateModified = time_format(item.dateModified)
        item.panelTime = time_format(item.panelTime)
      })
      route_replace(this.$route.path, { ...this.filter, cursor });
    } catch (error) {
      Notif.notif_error_api(error);
    } finally {
      this.isLoading = false;
    }
  }

  toPage(value: zoneTriggerDetailType["data"]) {
    this.$router.push(`open-trigger/${value.uuid}`);
  }

  getColor(status: string) {
    if (status == "Open" || status == "Re Open") return "primary";
    if (status == "Closed") return "secondary";
    if (status == "Resolved") return "success";
    if (status == "In Progress") return "warning";
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData(this.$route.query.cursor);
  }

  @Watch("filter.mode")
  onModeChange() {
    this.fetchData();
  }
}
