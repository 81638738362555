





















































































































































import { Component, Vue } from "vue-property-decorator";
import ReportFooter from "@/components/ReportFooter.vue";

@Component({ name: "panel-report", components: { ReportFooter } })
export default class PanelReport extends Vue {
  /* Variables */

  filters = {
    contarct_start_date: (null as unknown) as string,
    contarct_end_date: (null as unknown) as string,
    installation_start_date: (null as unknown) as string,
    installation_end_date: (null as unknown) as string,
    status: (null as unknown) as string,
    operation_mode: (null as unknown) as string,
    postcode: (null as unknown) as string,
    company_type: (null as unknown) as string,
    state: (null as unknown) as string,
    blank_postcode: (null as unknown) as string,
    blank_sim: (null as unknown) as string,
    blank_install_date: (null as unknown) as string,
    blank_Zones: (null as unknown) as string,
    blank_no_call: (null as unknown) as string,
  };

  /* Computed */
  /* Methods */

  doDownload(something: string[]) {
    console.log(something);
  }

  /* Life-cycle Methods */
}
