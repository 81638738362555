




































































































































































































































import { Component, Vue } from "vue-property-decorator";
import VerticalProgress from "@/components/VerticalProgress.vue";
import HorizontalProgress from "@/components/HorizontalProgress.vue";
import { DataTableHeader } from "vuetify";

@Component({
  name: "dashboard",
  components: { HorizontalProgress, VerticalProgress },
})
export default class Dashboard extends Vue {
  /* Variables */

  /* Computed */

  public get AreaHeaders(): DataTableHeader[] {
    return [
      { text: "Site Group", value: "siteGroup" },
      { text: "False Alarm", value: "falseAlarm" },
      { text: "Customer at Site", value: "customerSite" },
      { text: "Notified Tech", value: "notifiedTech" },
      { text: "Pending Tech", value: "pendingTech" },
      { text: "Maintance", value: "maintance" },
      { text: "Red Alarm", value: "redAlarm" },
      { text: "Not Attended", value: "notAttended" },
      { text: "Not Action", value: "notAction" },
    ];
  }

  /* Methods */

  /* Life-cycle Methods */
}
