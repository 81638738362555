enum endpoints {

    /* Authentications */

    // Authorization
    AUTH = '/oauth',

    // Revoke
    REVOKE = '/api/v1/authrevoke',

    // Profile
    ME = '/api/me',

    // MobileState
    MOBILESTATE = 'api/v1/mobilestate',


    // All-tickets
    TICKET = '/api/v1/ticket',

    // Ticket History
    TICKET_HISTORY = '/api/v1/ticket-history',

    // Ticket Log
    TICKET_LOG = 'api/v1/ticket-log',

    // Ticket Tracking
    TICKET_TRACKING = 'api/v1/ticket-tracking',

    // Ticket File
    TICKET_FILE = 'api/v1/ticket-file',

    // Ticket Category
    TICKET_CATEGORY = 'api/v1/ticket-category',

    // Ticket Actions
    TICKET_ACTIONS = 'api/v1/tickets',

    // Account
    ACCOUNT = '/api/v1/account',

    // Alarm
    ALARM = '/api/v1/ipc-alarm-log',

    // Device
    DEVICE = '/api/v1/ipc-devices',

    // Zone-Trigger
    ZONETRIGGER = '/api/v1/cms-zone-trigger',

    // Call List
    CONTACT_LIST = '/api/v1/cms-contact',

    // Tech-Group
    TECH_GROUP = '/api/v1/cms-tech-group-list',

    // Complaints
    COMPLAINTS = 'api/v1/complain',

    // Complaints Status
    COMPLAINTS_STATUS = 'api/v1/complain-status',

    // Complaints Category
    COMPLAINTS_CATEGORY = 'api/v1/complain-category',

    // Complaints Status Log
    COMPLAINTS_STATUS_LOG = 'api/v1/complain-status-log',

    // Complaints Response
    COMPLAINTS_RESPONSE = 'api/v1/complain-response',

    // Complaints Attachment
    COMPLAINTS_ATTACHMENT = 'api/v1/complain-attachment',

    // Request 
    REQUEST = 'api/v1/request',

    // Request Status
    REQUEST_STATUS = 'api/v1/request-status',

    // Request Category
    REQUEST_CATEGORY = 'api/v1/request-category',

    // Request Status Log
    REQUEST_STATUS_LOG = 'api/v1/request-status-log',

    // Request Response
    REQUEST_RESPONSE = 'api/v1/request-response',

    // Request Attachment
    REQUEST_ATTACHMENT = 'api/v1/request-attachment',

    // Technician 
    TECHNICIAN = 'api/v1/technician',

    // ComplaintsApp
    COMPLAINTS_APP = 'api/v1/complain-profile',

    // Master Company
    MASTER_COMPANY = 'api/v1/cms-master-company',

    // CMS Code
    CMS_CODE = 'api/v1/cms-code',

    // Site Group
    SITE_GROUP = 'api/v1/cms-site-group',

    // Escalation List
    ESCALATION_LIST = 'api/v1/cms-location',

    // CMS Team
    CMS_TEAM = 'api/v1/cms-tech-groups',

    // CMS Status
    CMS_STATUS = 'api/v1/cms-site-status',

    // CMS Site 
    CMS_SITE = '/api/v1/cms-site',

    // Notification
    NOTIFICATION = '/api/v1/notification',

    // Notification Read
    NOTIFICATION_READ = 'api/v1/notification-read',

    // Customer Detail
    CUSTOMER_INFO_ASSET = 'api/v1/atac-customer-asset',
    CUSTOMER_INFO_ID = 'api/v1/atac-customer',
    CUSTOMER_INFO_ESCALATION = 'api/v1/customer-atac-escalation-list',
    CUSTOMER_INFO_TECH = 'api/v1/techsupport',

    CUSTOMER_LAYOUT = 'api/v1/customer-atac-layout-plan',

    CUSTOM_NEARBY_TECH = 'api/v1/atac-nearest-technician',
    CUSTOM_NEARBY_SITE = 'api/v1/atac-nearest-site',

    // Rpc Calling Out PABX
    PABX_CALLOUT = 'api/v1/cms-pabx-callout',

}
export default endpoints;