import Vue from 'vue'
import App from './App.vue'
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify';
import * as VueGoogleMaps from "vue2-google-maps";
import './assets/custom.scss';
import config from './shared/config';
import firebase from 'firebase/app';

Vue.use(VueMoment, {
  moment
})

Vue.config.productionTip = false

firebase.initializeApp(config.FIREBASE_CONFIG)

Vue.use(VueGoogleMaps, {
  load: {
    key: config.GOOGLE_MAP_API,
    libraries: "geometry,places",
  },
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
