











import { Component, Vue } from "vue-property-decorator";

@Component({ name: "page-not-found" })
export default class PageNotFound extends Vue {}
