

















































































import { Component, Vue } from "vue-property-decorator";
import ReportFooter from "@/components/ReportFooter.vue";

@Component({ name: "governance-summary", components: { ReportFooter } })
export default class GovernanceSummary extends Vue {
  /* Variables */

  filters = {
    report: (null as unknown) as string,
    customer: (null as unknown) as string,
    region: (null as unknown) as string,
    start_date: (null as unknown) as string,
    end_date: (null as unknown) as string,
    output: (null as unknown) as string,
  };

  /* Computed */
  /* Methods */

  doDownload(something: string[]) {
    console.log(something);
  }

  /* Life-cycle Methods */
}
