import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export type NotifType = {
    type: 'info' | 'success' | 'warning' | 'error';
    massage: string;
} | null;

export type trigger = { main: string; secondary?: string }

@Module({ name: "NotifStore" })
export default class NotifStore extends VuexModule {
    private isVisible = false;
    private notifications: NotifType = { type: 'info', massage: '' };
    private trigger = { main: '', secondary: '' } as trigger

    /* Getters */

    public get is_visible(): boolean {
        return this.isVisible;
    }

    public get notif(): NotifType {
        return this.notifications;
    }

    public get triggers(): trigger {
        return this.trigger;
    }


    /* Mutations */

    @Mutation
    public set_visibilty(value: boolean) {
        this.isVisible = value
    }

    @Mutation
    public set_notif(notif: NotifType) {
        this.notifications = notif
    }

    @Mutation
    public set_trigger(triggers: trigger) {        
        this.trigger = triggers
    }

    /* Actions */

    @Action
    notif_error(message: string) {
        this.context.commit("set_visibilty", true)
        this.context.commit("set_notif", { type: 'error', message })
    }

    @Action
    notif_error_api(error: any) {
        const message: string = error.response ? error.response.data.detail : error.message;
        this.context.commit("set_visibilty", true)
        this.context.commit("set_notif", { type: 'error', message })
    }

    @Action
    notif_info(message: string) {
        this.context.commit("set_visibilty", true)
        this.context.commit("set_notif", { type: 'info', message })
    }

    @Action
    notif_warning(message: string) {
        this.context.commit("set_visibilty", true)
        this.context.commit("set_notif", { type: 'warning', message })
    }

    @Action
    notif_success(message: string) {
        this.context.commit("set_visibilty", true)
        this.context.commit("set_notif", { type: 'success', message })
    }

    @Action
    refresh_trigger(triggers: {main: string; secondary: string}) {
        this.context.commit('set_trigger', triggers)
    }
}