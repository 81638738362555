


















import { Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";

@Component({ name: "notification-bar" })
export default class NotificationBar extends Vue {
  public get isVisible(): boolean {
    return Notif.is_visible;
  }

  public set isVisible(v: boolean) {
    Notif.set_visibilty(v);
  }

  public get notifMessage() {
    return Notif.notif;
  }
}
