























































































import { Component, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { Notif } from "@/store";
import { fullName, route_replace, time_format } from "@/shared/utils";
import DatePicker from "@/components/DatePicker.vue";
import {
  get_account,
  get_request,
  get_request_category,
  get_request_status,
} from "@/api";
import {
  requestCategoryType,
  requestStatusType,
  requestType,
} from "@/api/api.types";
import StatusChip from "@/components/StatusChip.vue";

@Component({
  name: "request-index",
  components: { DatePicker, StatusChip },
})
export default class RequestIndex extends Vue {
  /* Variables */

  isLoading = false;

  filter = {
    start_date: this.$route.query.start_date || null,
    end_date: this.$route.query.end_date || null,
    request_status_uuid: this.$route.query.request_status_uuid || null,
    request_category_uuid: this.$route.query.request_category_uuid || null,
    account: this.$route.query.account || null,
  };

  statusList = [] as requestStatusType["data"]["_embedded"]["requestStatus"];

  categoryList = [] as requestCategoryType["data"]["_embedded"]["requestCategory"];

  accountList = [] as { value: string; text: string }[];

  date = this.filter.start_date
    ? [this.filter.start_date, this.filter.end_date]
    : [];

  tableItems = [] as requestType["data"]["_embedded"]["request"];

  page = {
    page_size: 0,
    page_count: 1,
    page: 1,
  };

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Title", value: "title" },
      { text: "Requested By", value: "requestedBy.firstName" },
      { text: "Company", value: "account.name" },
      { text: "Category", value: "requestCategory" },
      { text: "Status", value: "requestStatus" },
      { text: "Requested Time", value: "createdAt" },
      { text: "", value: "actions" },
    ];
  }

  /* Method */

  async fetchData(toPage = this.page.page) {
    if (toPage == 0) toPage = 1;
    this.isLoading = true;
    try {
      const resp = await get_request({ ...this.filter, page: toPage });
      this.tableItems = resp.data._embedded.request;
      this.tableItems.forEach((item) => {
        item.createdAt = time_format(item.createdAt);
        item.requestedBy.firstName = fullName(
          item.requestedBy.firstName,
          item.requestedBy.lastName
        );
      });
      route_replace(this.$route.path, { ...this.filter, page: toPage });
    } catch (error) {
      Notif.notif_error_api(error);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchStatusList() {
    try {
      const resp = await get_request_status({ order: "order", ascending: 1 });
      this.statusList = resp.data._embedded.requestStatus;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchCategoryList() {
    try {
      const resp = await get_request_category({ order: "order", ascending: 1 });
      this.categoryList = resp.data._embedded.requestCategory;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchAccountList() {
    try {
      const resp = await get_account();
      const data = resp.data._embedded.account.map((item) => {
        return { value: item.uuid, text: item.name };
      });
      this.accountList = [
        { value: (null as unknown) as string, text: "All Company" },
        ...data,
      ];
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  /* Life-cycle Method */

  mounted() {
    this.fetchData();
    setInterval(() => {
      this.fetchData();
    }, 60000);
    this.fetchStatusList();
    this.fetchCategoryList();
    this.fetchAccountList();
  }

  @Watch("page.page")
  onPageChange(next: number) {
    if (next) this.fetchData();
  }
}
