





















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { Notif } from "@/store";
import { route_replace } from "@/shared/utils";
import { get_cms_site, get_cms_status, get_cms_team } from "@/api";
import { cmsSiteType, cmsStatus } from "@/api/api.types";
import ToolTruncate from "@/components/ToolTruncate.vue";

type listType = { value: string; text: string };

@Component({
  name: "search-site",
  components: { ToolTruncate },
})
export default class SearchSite extends Vue {
  /* Variables */

  isLoading = false;

  filter = {
    company_name: this.$route.query.company_name || null,
    location_name: this.$route.query.location_name || null,
    address: this.$route.query.address || null,
    telno: this.$route.query.telno || null,
    status: Number.parseInt(this.$route.query.status as string) || null,
    tech_group: this.$route.query.tech_group || null,
    repair_group: this.$route.query.repair_group || null,
    complaint_group: this.$route.query.complaint_group || null,
  };

  teamSearch = {
    tech: "",
    repair: "",
    complaint: "",
  };

  statusList = [] as cmsStatus["data"]["_embedded"]["cstatus"];

  teamList = {
    tech: [] as listType[],
    repair: [] as listType[],
    complaint: [] as listType[],
  };

  tableItems = (null as unknown) as cmsSiteType["data"]["_embedded"]["site"];

  page = {
    page_count: 0,
    page_size: 10,
    page: 0,
  };

  /* Computed */

  public get headers(): DataTableHeader[] {
    return [
      { text: "Site ID", value: "uuid" },
      { text: "Site Name", value: "companyName" },
      { text: "Site Code", value: "locationName", width: "110px" },
      { text: "Address", value: "address" },
      { text: "Telephone No", value: "telno" },
      { text: "Status", value: "statusId.status" },
      { text: "Escalation List", value: "locationId.location" },
      { text: "Tech Team", value: "techGroupId.groupName" },
      { text: "History", value: "history", width: "100px" },
    ];
  }

  /* Method */

  async fetchData(toPage = this.page.page) {
    if (toPage == 0) toPage = 1;
    this.isLoading = true;
    try {
      const resp = await get_cms_site({ ...this.filter, page: toPage });
      this.tableItems = resp.data._embedded.site;
      const { page, page_count, page_size } = resp.data;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, { ...this.filter, page: toPage });
    } catch (error) {
      Notif.notif_error_api(error);
    } finally {
      this.isLoading = false;
    }
  }

  async fetchGroup(name: string) {
    try {
      const resp = await get_cms_team({ name });
      const temp = [] as listType[];
      resp.data._embedded.groups.forEach((value) => {
        temp.push({ value: value.uuid.toString(), text: value.groupName });
      });
      return temp;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  async fetchStatus() {
    try {
      const resp = await get_cms_status();
      this.statusList = resp.data._embedded.cstatus;
    } catch (error) {
      Notif.notif_error_api(error);
    }
  }

  /* Life-cycle Method */

  mounted() {
    if (Object.keys(this.$route.query).length > 1) {
      this.fetchData();
    }
    this.fetchStatus();
  }

  @Watch("page.page")
  onPageChange(now: number) {
    if (now) this.fetchData(now);
  }

  @Watch("teamSearch.tech")
  onTechChange(now: string) {
    this.fetchGroup(now).then((resp) => {
      if (resp) this.teamList.tech = resp;
    });
  }

  @Watch("teamSearch.repair")
  onRepairChange(now: string) {
    this.fetchGroup(now).then((resp) => {
      if (resp) this.teamList.repair = resp;
    });
  }

  @Watch("teamSearch.complaint")
  onComplaintChange(now: string) {
    this.fetchGroup(now).then((resp) => {
      if (resp) this.teamList.complaint = resp;
    });
  }
}
