















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import url from "@/api/api.constants";

@Component({ name: "report-footer" })
export default class ReportFooter extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly filters: any;

  @Prop({ required: true }) readonly api!: keyof typeof url;

  /* Methods */

  @Emit("reset")
  reset() {
    return null;
  }

  @Emit("download")
  download() {
      return [url[this.api], this.filters]
  }
}
